import React, { useContext, useEffect } from "react";
import Layout from "../../common/Layout";
import "../../styles/FreelancerClientList.css";
import userContext from "../../context/UserContext";
import { Link } from "react-router-dom";
function FreelancerClientList() {
  const context = useContext(userContext);
  const { fetchAllClients, clientData, deleteClient } = context;

  useEffect(() => {
    fetchAllClients();
    console.log(clientData);
  }, []);

  const deleteHandle = async (clientId) =>{
    try {
      await deleteClient(clientId);
      console.log("Successfully Deleted!!")
      appendAlert("Client Deleted Successfully.", "success");
    } catch (error) {
      console.log("Error Occurred!!")
      appendAlert("Please try again later.", "danger");
    }
  }

  const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
  const appendAlert = (message, type) => {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('')
  
    alertPlaceholder.append(wrapper)
  }
  return (
    <Layout btnStatus="none" setMWidth="95%">
      <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
      <div
        className="heading-div bank-info-heading-div"
        style={{ width: "100%", marginLeft: "70px" }}
      >
        <h5 className="valuta-heading bank-info-heading">Visa kund</h5>
      </div>
      <div className="client-list-container">
        <div className="row client-list-row">
          <div className="col client-data-col-div">
            <h6 className="bold-txt orgNr">Org. Nr: / ID. Nr:</h6>
            {clientData?.map((data, index) => (
              <div key={index} className="client-data-col">
              <h6>
                {data.clientOption === "private"
                  ? data.privateClient.pvtIdNumber
                  : data.organizationClient.orgIdNumber}
              </h6>
              </div>
            ))}
          </div>
          <div className="col  client-data-col-div">
            <h6 className="bold-txt">Företagets/privatpersons</h6>
            {clientData?.map((data, index) => (
              <div key={index} className="client-data-col">
              <h6>
                {data.clientOption === "private"
                  ? `${data.privateClient.pvtFirstName} ${data.privateClient.pvtLastName}`
                  : `${data.organizationClient.orgFirstName} ${data.organizationClient.orgLastName}`}
              </h6>
              </div>
            ))}
          </div>
          <div className="col  client-data-col-div">
            <h6 className="bold-txt">Valutakod</h6>
            {clientData?.map((data, index) => (
              <div key={index} className="client-data-col">
              <h6>
                {data.clientOption === "private"
                  ? data.privateClient.pvtCurrencyCode
                  : data.organizationClient.orgCurrencyCode}
              </h6>
              </div>
            ))}
          </div>
          <div className="col  client-data-col-div">
            <h6>Redigera</h6>
            {clientData?.map((data, index) => (
              <div key={index} className="client-data-col">
                <Link to={`/freelancer-edit-client/${data._id}`}  className="editClientBtn" >
                  Redigera
                </Link>
                |<Link to={`/client-invoices/${data._id}`}>Visa Fakturor</Link>
                |<Link style={{color: 'red'}} onClick={()=>{deleteHandle(data._id)}} to={"#"}>Radera</Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default FreelancerClientList;
