import React, { useState } from 'react';
import Signin from '../../common/Signin'
import FreelancerSignupForm from './FreelancerSignupForm';

const FreelancerSignup = () => {
    const freelancerImage = require('../../assets/Element.png');

  return (
    <>
      <Signin flImage = {freelancerImage}>
          <FreelancerSignupForm />
        </Signin>
    </>
  );
};

export default FreelancerSignup;

  