import React from 'react'
import Layout from '../../common/Layout'
import { Link } from 'react-router-dom'
import '../../styles/FreelancerRecruitMember.css'
function FreelancerRecruitMemberSection() {
  return (
    <Layout btnStatus='none' setMWidth="95%">
      <div className='recruit-div mt-4'>
        <h6 className='recruit-heading'>Du måste läsa och godkänna avtalet.</h6>
        <div className='row rec-btn-div'>
          <div className='col'>
            <Link className='btn-heading' to="https://globalworker.se/PDF/avtal-utlandet-globalworker%20(pdf.io).pdf" target='_blank'>
            <button className='btn btn-primary rec-btn'>Klicka här för att läsa affiliate avtal (öppnas i ny flik)</button>
            </Link>
          </div>
          <div className='col'>
            <button className='btn btn-primary rec-btn'>Godkänn avtalet</button>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default FreelancerRecruitMemberSection

