import React, { useState, useContext, useEffect } from "react";
import Layout from "../../common/Layout";
import "../../styles/FreelancerUserProfile.css";
import PrivateUserform from "./PrivateUserform";
import OrganizationUserForm from "./OrganizationUserForm";
import userContext from "../../context/UserContext";
import Spinner from "./Spinner";
import { useNavigate } from "react-router-dom";

function FreelancerUserProfile() {
  const navigate = useNavigate();
  const context = useContext(userContext);
  const { firstName, saveUserProfile, setProfileName, getUser, user, getBankInfoData, userBankingData, setBankInfoModal} = context;
  const [selectedOption, setSelectedOption] = useState("private");
  const [loading, setLoading] = useState(true);
  const [submitBtnTrigger, setSubmitBtnTrigger] = useState({loading: false, disbaled: false});
  
  const [privateUserInfo, setPrivateUserInfo] = useState({
    pvtFname: "",
    pvtLname: "",
    pvtUserEmail: "",
    pvtTel: "",
    pvtMobNo: "",
    pvtSkypeName: "",
  });

  const [organizationUserInfo, setOrganizationUserInfo] = useState({
    organizationName: "",
    organizationFname: "",
    organizationLname: "",
    organizationUserEmail: "",
    organizationTel: "",
    organizationMobNo: "",
    organizationSkypeName: "",
  });
  const apiUrl = 'https://globalworker-backend.vercel.app';
  useEffect(() => {
    
    const getUserProfileData = async () =>{
      try {
        const response = await fetch(`${apiUrl}/api/userprofile/getuserprofile`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem('token'),
          },
        })
        const profileData = await response.json();
        console.log(profileData);
        
        if (profileData && profileData.profileOption) {
          setSelectedOption(profileData.profileOption);
        }
        setLoading(false);
        // console.log(profileData);
      } catch (error) {
        console.error("Error while getting user profile:", error);
      }
    }
    getUser();
    getUserProfileData();
    getBankInfoData()
    console.log(user);
  }, []);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const onChangeHandle = (e) => {
    setPrivateUserInfo((prev) => {
      let helper = { ...prev };
      helper[`${e.target.id}`] = e.target.value;
      return helper; // Add this return statement
    });
  };

  const onChangeMobileNo = (e) =>{
    let inputValue = e.target.value;
    let sanitizedValue = inputValue.replace(/[^+\d-]/g, ''); // Remove anything that is not a plus symbol, digit, or hyphen
    
    if (!sanitizedValue) {
      sanitizedValue = ''; // Change this to the desired default country code
  } else {
      // Check if the input doesn't start with a plus symbol
      if (!sanitizedValue.startsWith('+')) {
          // Assuming country code is '46', replace it with the actual country code
          sanitizedValue = '+' + sanitizedValue;
      }
  }

    // Check if there's a plus symbol not at the start and remove it
    if (sanitizedValue.startsWith('+')) {
      sanitizedValue = '+' + sanitizedValue.slice(1).replace(/\+/g, '');
    } else {
      sanitizedValue = sanitizedValue.replace(/\+/g, '');
    }

    setPrivateUserInfo((prev) => ({
      ...prev,
      pvtMobNo: sanitizedValue
    }));
  }
  
  const onChangeTelNo = (e) =>{
    let inputValue = e.target.value;
    let sanitizedValue = inputValue.replace(/[^+\d-]/g, ''); // Remove anything that is not a plus symbol, digit, or hyphen
    if (!sanitizedValue) {
        sanitizedValue = ''; // Change this to the desired default country code
    } else {
        // Check if the input doesn't start with a plus symbol
        if (!sanitizedValue.startsWith('+')) {
            // Assuming country code is '46', replace it with the actual country code
            sanitizedValue = '+' + sanitizedValue;
        }
    }
    // Check if there's a plus symbol not at the start and remove it
    if (sanitizedValue.startsWith('+')) {
      sanitizedValue = '+' + sanitizedValue.slice(1).replace(/\+/g, '');
    } else {
      sanitizedValue = sanitizedValue.replace(/\+/g, '');
    }

    setPrivateUserInfo((prev) => ({
      ...prev,
      pvtTel: sanitizedValue
    }));
  }

  const onChangeOrgHandle = (e) => {
    setOrganizationUserInfo((prev) => {
      let helper = { ...prev };
      helper[`${e.target.id}`] = e.target.value;
      return helper; // Add this return statement
    });
  };  

  const handleSaveToBank = async (e) =>{
    try {
      e.preventDefault();
      await handleSave(e);
      setBankInfoModal(true);
      navigate('/freelancer-bank-information');
    } catch (error) {
      console.log("Error occured");
      appendAlert("Please try again later.", "danger");
    }
  }

  const handleSave = async (e) => {
    e.preventDefault();
    setSubmitBtnTrigger({loading: true, disbaled: true})
    try {
      let newProfileName = "";
      let response = false;
      if (selectedOption === "private") {
        response = await saveUserProfile({profileOption: selectedOption, privateUserInfo: privateUserInfo});
        newProfileName = `${privateUserInfo.pvtFname} ${privateUserInfo.pvtLname}`;
      } else if (selectedOption === "organization") {
        response = await saveUserProfile({profileOption: selectedOption, organizationUserInfo: organizationUserInfo});
        newProfileName = `${organizationUserInfo.organizationFname} ${organizationUserInfo.organizationLname}`;
      }
      if(response){
        console.log("Data Saved Successfully");
        appendAlert("Data Saved Successfully.", "success");
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setProfileName(newProfileName)
        setSubmitBtnTrigger({loading: false, disbaled: false})
      }
      else{
        setSubmitBtnTrigger({loading: false, disbaled: false})
        console.log("Error saving user profile");
        appendAlert("Please try again later.", "danger");
      }
    } catch (error) {
      setSubmitBtnTrigger({loading: false, disbaled: false})
      appendAlert("Please try again later.", "danger");
      console.error("Error saving user profile:", error);
    }
  };

  const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
  const appendAlert = (message, type) => {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('')
  
    alertPlaceholder.append(wrapper)
  }

  return (
    <Layout btnStatus="none" setMWidth="95%">
      <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
      {loading && <Spinner/>}
      {!loading && <>
      <div className="heading-div" style={{width: '100%', marginLeft: '70px'}}>
        <h5 className="valuta-heading">Kontaktuppgifter</h5>
      </div>
      <div className="main-container-fl">
        <form  onSubmit={userBankingData ? handleSave : handleSaveToBank} className="was-validated left-container-fl">
          <div className="user-prof-radio-container">
            <div className="Privatpersons-container">
              {/* <h6 className="Privatpersons-heading">Privatpersons eller</h6> */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="private"
                  checked={selectedOption === "private"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Privatperson
                </label>
              </div>
            </div>

            <div className="företags-kund-container" style={{display: 'none'}}>
              <h6 className="företags-kund-heading">företags kund</h6>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="organization"
                  checked={selectedOption === "organization"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Företag
                </label>
              </div>
            </div>
          </div>
          <div>
            {selectedOption === "private" && (
              <PrivateUserform onChange={onChangeHandle} values={privateUserInfo} setPrivateUserInfo={setPrivateUserInfo} setSelectedOption={setSelectedOption} onChangeMobileNo={onChangeMobileNo} onChangeTelNo={onChangeTelNo}/>
            )}
            {/* {selectedOption === "organization" && (
              <OrganizationUserForm orgOnChange={onChangeOrgHandle} orgValues={organizationUserInfo} setSelectedOption={setSelectedOption} setOrganizationUserInfo={setOrganizationUserInfo}/>
            )} */}
            <div className="user-profile-save-btn-div mb-4">
                <button type="submit" disabled={submitBtnTrigger.disbaled} className='btn btn-primary user-profile-save-btn'>Spara</button>
            </div>
            <div style={{display: 'flex', marginLeft: '46px'}}>
              {submitBtnTrigger.loading && <Spinner/>}
            </div>
          </div>
        </form>
        <div className="right-container-fl">
          <img src={require("../../assets/Element (1).png")} alt="no img" />
        </div>
      </div>
      
      </>}
    </Layout>
  );
}

export default FreelancerUserProfile;
