import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../freelancer/components/Spinner'
const Forgotpasswordform = ({ onBackToLogin }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleForgotPasswordSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await fetch('https://globalworker-backend.vercel.app/api/auth/forgotpassword',{
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({email}),
      })
      if(!response.ok){
        throw new Error("Email does not exists.")
      }
      const res = await response.json();
      console.log(res)
      setLoading(false)
      console.log("Email sent. please check your inbox.")
      appendAlert("E-post skickat. kolla din inkorg.", "success");

    } catch (error) {
      setLoading(false)
      console.log("Error. please try again later")
      appendAlert("Please try again later.", "danger");
    }
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

const alertPlaceholder = document.getElementById("liveAlertPlaceholder");
  const appendAlert = (message, type) => {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      "</div>",
    ].join("");

    alertPlaceholder.append(wrapper);
  };

  return (
    <form className='forgotPassForm' onSubmit={handleForgotPasswordSubmit}>
      <div id="liveAlertPlaceholder"></div>
      <h3 id="login-heading">Glömt ditt lösenord?</h3>
      <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="languageDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Svenska (Sverige)
            </button>
            <ul className="dropdown-menu" aria-labelledby="languageDropdown">
              <li>
                <a className="dropdown-item" href="#" onClick={() => changeLanguage('Svenska (Sverige)')}>
                  Svenska (Sverige)
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#" onClick={() => changeLanguage('English')}>
                  English
                </a>
              </li>
              {/* Add more language options as needed */}
            </ul>
          </div>
          <hr id="divider" className='mt-4'/>
      <label className="form-lebels mb-2" htmlFor="email">
        E-post
      </label>
      <input
        type="email"
        id="email"
        className="form-control mb-2"
        placeholder="example123@gmail.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button
        type="submit"
        className="btn btn-primary mt-3 mb-2" id="login-btn"
      >
        Hämta mitt lösenord
      </button>
      <p>
      Har du redan ett konto?{' '}
        <Link to={"#"} onClick={onBackToLogin}>
          Logga in
        </Link>
      </p>
      <div className='mt-4'>
        {loading && <Spinner/>}
      </div>
    </form>
  );
};

export default Forgotpasswordform;

function changeLanguage(language) {
    document.getElementById('languageDropdown').innerText = language;
  }