import React from 'react'
import { Navigate, useLocation } from 'react-router-dom';
const AuthCheck = (Component) => {
    const AuthComponent = (props) => {
        const location = useLocation();
        const isAuthenticated = localStorage.getItem('token');
    
        if (isAuthenticated && location.pathname === '/signin' || isAuthenticated && location.pathname === '/signup') {
          return <Navigate to="/" />;
        }

        if(location.pathname === '/signin'){
          document.title = 'Freelancer - Sign In'
        }
        else if(location.pathname === '/signup'){
          document.title = 'Freelancer - Sign Up'
        }
    
        // Render the protected component if authenticated
        return <Component {...props} />;
      };
      return AuthComponent
}

export default AuthCheck
