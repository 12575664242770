import React, { useState } from 'react';
import Signin from '../../common/Signin'
import Signinform from '../../common/Signinform';
import Forgotpasswordform from '../../common/Forgotpasswordform';
import FreelancerSignup from './FreelancerSignup';
import FreelancerSignupForm from './FreelancerSignupForm';

function FreelancerSignin() {
    const [view, setView] = useState('login'); // 'login' or 'forgotPassword'
    const handleForgotPasswordClick = () => {
      setView('forgotPassword');
    };
  
    const handleBackToLogin = () => {
      setView('login');
    };

    const handleToRegister = () =>{
      setView('register');
    }

    const freelancerImage = require('../../assets/Element.png');
  return (
    <Signin flImage = {freelancerImage}>
        
        {view === 'login' && (
          <Signinform onToRegister={handleToRegister} displayStatus={''} onForgotPasswordClick={handleForgotPasswordClick} />
        )}
        {view === 'forgotPassword' && (
          <Forgotpasswordform onBackToLogin={handleBackToLogin} />
        )}
        {view === 'register' && (
          <FreelancerSignupForm  />
        )}
    </Signin>
  )
}

export default FreelancerSignin
