import React, { useContext, useState } from "react";
import Layout from "../../common/Layout";
import "../../styles/FreelancerUserProfile.css";
import "../../styles/FreelancerCreateClient.css";
import PrivateClientForm from "./PrivateClientForm";
import OrganizationClientForm from "./OrganizationClientForm";
import userContext from "../../context/UserContext";
import Spinner from './Spinner';
function FreelancerCreateClient() {
    const context = useContext(userContext);
    const {createClient} = context;
    const [selectedOption, setSelectedOption] = useState("organization");
    const [loading, setLoading] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [privateClientInfo, setPrivateClientInfo] = useState({
    pvtIdNumber: '',
    pvtFirstName: '',
    pvtLastName: '',
    pvtRecipientEmail: '',
    pvtReferenceName: '',
    pvtContactNumber: '',
    pvtCurrencyCode: 'Markera Valuta',
    pvtStreet: '',
    pvtPostalCode: '',
    pvtCity: '',
    pvtCountry: 'Markera Landet',
  });

  const [organizationClientInfo, setOrganizationClientInfo] = useState({
    orgIdNumber: '',
    orgVATNumber: '',
    orgFirstName: '',
    orgLastName: '',
    orgRecipientEmail: '',
    orgReferenceName: '',
    orgContactNumber: '',
    orgCurrencyCode: '',
    orgStreet: '',
    orgPostalCode: '',
    orgCity: '',
    orgCountry: '',
  });

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const onChangeHandle = (e) => {
    setPrivateClientInfo((prev) => {
      let helper = { ...prev };
      helper[`${e.target.id}`] = e.target.value;
      return helper; 
    });
  };
  
  const onChangeOrgHandle = (e) => {
    setOrganizationClientInfo((prev) => {
      let helper = { ...prev };
      helper[`${e.target.id}`] = e.target.value;
      return helper; 
    });
  };  

  const onChangePvtMobNo = (e) =>{
    let inputValue = e.target.value;
    let sanitizedValue = inputValue.replace(/[^+\d-]/g, ''); // Remove anything that is not a plus symbol, digit, or hyphen

    // Check if there's a plus symbol not at the start and remove it
    if (sanitizedValue.startsWith('+')) {
      sanitizedValue = '+' + sanitizedValue.slice(1).replace(/\+/g, '');
    } else {
      sanitizedValue = sanitizedValue.replace(/\+/g, '');
    }

    setPrivateClientInfo((prev) => ({
      ...prev,
      pvtContactNumber: sanitizedValue
    }));
  }

  const onChangeOrgMobNo = (e) =>{
    let inputValue = e.target.value;
    let sanitizedValue = inputValue.replace(/[^+\d-]/g, ''); // Remove anything that is not a plus symbol, digit, or hyphen

    // Check if there's a plus symbol not at the start and remove it
    if (sanitizedValue.startsWith('+')) {
      sanitizedValue = '+' + sanitizedValue.slice(1).replace(/\+/g, '');
    } else {
      sanitizedValue = sanitizedValue.replace(/\+/g, '');
    }

    setOrganizationClientInfo((prev) => ({
      ...prev,
      orgContactNumber: sanitizedValue
    }));
  }

  const handleSave = async (e) =>{
    e.preventDefault();
    setLoading(true)
    setDisableBtn(true)
    try {
      if(selectedOption === "private"){
        await createClient({clientOption: selectedOption, privateClient: privateClientInfo})
        console.log("Created Successfully");
        appendAlert("Client created successfully!!", "success");
        setPrivateClientInfo({
          pvtIdNumber: '',
          pvtFirstName: '',
          pvtLastName: '',
          pvtRecipientEmail: '',
          pvtReferenceName: '',
          pvtContactNumber: '',
          pvtCurrencyCode: 'Markera Valuta',
          pvtStreet: '',
          pvtPostalCode: '',
          pvtCity: '',
          pvtCountry: 'Markera Landet',
        });
        setLoading(false)
        setDisableBtn(false)

      } else if(selectedOption === "organization"){
        await createClient({clientOption: selectedOption, organizationClient: organizationClientInfo})
        console.log("Created Successfully");
        appendAlert("Client created successfully!!", "success");
        setOrganizationClientInfo({
          orgIdNumber: '',
          orgVATNumber: '',
          orgFirstName: '',
          orgLastName: '',
          orgRecipientEmail: '',
          orgReferenceName: '',
          orgContactNumber: '',
          orgCurrencyCode: '',
          orgStreet: '',
          orgPostalCode: '',
          orgCity: '',
          orgCountry: '',
        })
        setLoading(false)
        setDisableBtn(false)
      }
      else{
        console.log("Please Select a valid option");
        appendAlert("Error Occurred. Please select valid option.", "danger");
        setLoading(false)
        setDisableBtn(false)
      }
    } catch (error) {
      console.log("Error Occured", error);
      appendAlert("Error Occurred. please try again later.", "danger");
      setLoading(false)
      setDisableBtn(false)
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const alertPlaceholder = document.getElementById('createClientAlert')
  const appendAlert = (message, type) => {
  const wrapper = document.createElement('div')
  wrapper.innerHTML = [
    `<div class="alert alert-${type} alert-dismissible" role="alert">`,
    `   <div>${message}</div>`,
    '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
    '</div>'
  ].join('')

  alertPlaceholder.append(wrapper)
}

  return (
    <Layout btnStatus="none" setMWidth="95%">
      <div id="createClientAlert" style={{width: '100%'}}></div>
      <div className="heading-div" style={{width: '100%', marginLeft: '70px'}}>
        <h5 className="valuta-heading fl-create-client-heading">Skapa/Redigera Företagskund eller Privatkund</h5>
      </div>
      <form onSubmit={handleSave} className="main-container-fl">
        <div className="">
          <div className="user-prof-radio-container">
            <div className="företags-kund-container" style={{marginRight: '30px'}}>
              <h6 className="företags-kund-heading">Företagskund</h6>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="organization"
                  checked={selectedOption === "organization"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Företag
                </label>
              </div>
            </div>

            <div className="Privatpersons-container">
              <h6 className="Privatpersons-heading">Privatkund</h6>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="private"
                  checked={selectedOption === "private"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                Privatkund
                </label>
              </div>
            </div>
          </div>
          <div>
            {selectedOption === "private" && (
              <PrivateClientForm onChange={onChangeHandle} values={privateClientInfo} onChangePvtMobNo={onChangePvtMobNo}/>
            )}
            {selectedOption === "organization" && (
              <OrganizationClientForm onChange={onChangeOrgHandle} values={organizationClientInfo} onChangeOrgMobNo={onChangeOrgMobNo}/>
            )}
            <div className="user-profile-save-btn-div mb-4">
                <button disabled={disableBtn} type="submit" className='btn btn-primary user-profile-save-btn'>Skapa</button>
            </div>
            <div style={{display: 'flex', marginLeft: "46px"}}>
              {loading && <Spinner/>}
            </div>
          </div>
        </div>
      </form>
    </Layout>
  )
}

export default FreelancerCreateClient
