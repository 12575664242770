import React from 'react'
import '../../styles/Footer.css'

function Footer() {
  return (
    <footer className="d-flex align-items-end justify-content-start footer-div">
        <small className="copyWrite">&copy; 2024-2025 Globalworker. All rights reserved.</small>
    </footer>
  )
}

export default Footer
