import React, { useState, useEffect, useRef, useContext } from "react";
import userContext from "../../context/UserContext";
import { useNavigate, Link } from "react-router-dom";
import Spinner from "./Spinner";

function FreelancerCreateInvoice({ setFirstInvoice, firstInvoice, setSentStatus, sentStatus, allInvoiceDetails, onInvoiceDetails, invId, setInvoiceData, invoiceData, optionselect, invSecOption }) {
  const context = useContext(userContext);
  const { addInvoiceAmount, invoiceId, deleteInvoice, userProfile } = context;
  const navigate = useNavigate();
  const initialInvoiceDetails = {
    invoiceId: invoiceId || invId,
    unit: "timmar",
    quantity: "-",
    fixedPrice: null,
    price: "-",
    workDescription: "-",
    totalAmount: "-",
  };

  const [radioClicked, setRadioClicked] = useState(false);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(initialInvoiceDetails);
  const [selectedUnit, setSelectedUnit] = useState("Timmar");
  const [disabledFixedPriceField, setDisabledFixedPriceField] = useState(true);
  const [disabledQuantityAndPrice, setDisabledQuantityAndPrice] =
    useState(false);
  const [disabledBgColor, setDisabledBgColor] = useState(
    "form-control disabled-field"
  );
  const [qtyAndPriceBgClr, setQtyAndPriceBgClr] = useState("");
  const [hoursWorked, setHoursWorked] = useState("");
  const [hourlyPrice, setHourlyPrice] = useState("");
  const [fixedPriceValue, setFixedPriceValue] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const apiUrl = 'https://globalworker-backend.vercel.app';
  const resetStates = () => {
    setInvoiceDetails(initialInvoiceDetails);
    setSelectedUnit("timmar");
    setDisabledFixedPriceField(true);
    setDisabledQuantityAndPrice(false);
    setDisabledBgColor("form-control disabled-field");
    setQtyAndPriceBgClr("");
    setHoursWorked("");
    setHourlyPrice("");
    setFixedPriceValue("");
    setTotalAmount(0);
    setTextAreaValue("");
  };

  const unitOnChangeHandle = (e) => {
    resetStates();
    const selectedValue = e.target.value;
    setSelectedUnit(selectedValue);
    setDisabledFixedPriceField(selectedValue !== "fast-pris");

    if (selectedValue === "Timmar" || selectedValue === "st") {
      setDisabledBgColor("form-control disabled-field");
      setDisabledQuantityAndPrice(false);
      setQtyAndPriceBgClr("");
    } else if (selectedValue === "fast-pris") {
      setDisabledBgColor("form-control");
      setDisabledQuantityAndPrice(true);
      setQtyAndPriceBgClr("#dddddd");
    }
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      unit: selectedValue,
    }));
  };

  const onChangeHoursHandle = (e) => {
    let value = parseFloat(e.target.value, 10);
    if (value < 0 || isNaN(value)) {
      setHoursWorked("");
    } else {
      setHoursWorked(value);
    }
    setInvoiceDetails((prevDetails) => ({ ...prevDetails, quantity: value }));
  };

  const onChangeHourlyPriceHandle = (e) => {
    let value = parseFloat(e.target.value);
    if (value < 0 || isNaN(value)) {
      setHourlyPrice("");
    } else {
      setHourlyPrice(value);
    }
    setInvoiceDetails((prevDetails) => ({ ...prevDetails, price: value }));
  };

  const onChangeFixedPriceHandle = (e) => {
    setFixedPriceValue(e.target.value);
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      fixedPrice: e.target.value,
    }));
  };

  useEffect(() => {
    let total = 0;
    if (selectedUnit === "fast-pris") {
      setTotalAmount(parseFloat(fixedPriceValue) || 0);
      total = parseFloat(fixedPriceValue) || 0;
    } else {
      if (hoursWorked !== "" && hourlyPrice !== "") {
        total = hoursWorked * hourlyPrice;
        setTotalAmount(total);
      } else {
        setTotalAmount(0);
      }
    }
    setInvoiceDetails((prevDetails) => ({ ...prevDetails, totalAmount: total }));
  }, [selectedUnit, hoursWorked, hourlyPrice, fixedPriceValue]);

  const onChangeTextAreaHandle = (e) => {
    setTextAreaValue(e.target.value);
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      workDescription: e.target.value,
    }));
  };

  const addAmount = async (e) => {
    e.preventDefault();
    try {
      if (invoiceDetails.unit === "timmar" || invoiceDetails.unit === "st") {
        const newAmount = await addInvoiceAmount({
          invoiceId: invoiceDetails.invoiceId,
          unit: invoiceDetails.unit,
          quantity: invoiceDetails.quantity,
          price: invoiceDetails.price,
          workDescription: invoiceDetails.workDescription,
        });
        if (optionselect) {
          setInvoiceData((prevInvoiceData) => {
            const updatedInvoiceData = { ...prevInvoiceData };
    
            if (!updatedInvoiceData.invoiceAmount) {
              updatedInvoiceData.invoiceAmount = [];
            }
    
            updatedInvoiceData.invoiceAmount.push(newAmount);
    
            // Recalculate totalAmount
            const totalAmount = updatedInvoiceData.invoiceAmount.reduce(
              (acc, item) => acc + parseFloat(item.totalAmount || 0),
              0
            );
    
            updatedInvoiceData.invoice.totalAmount = totalAmount.toFixed(2);
    
            return updatedInvoiceData;
          });
        }
      } else if (invoiceDetails.unit === "fast-pris") {
        const newAmount = await addInvoiceAmount({
          invoiceId: invoiceDetails.invoiceId,
          unit: invoiceDetails.unit,
          fixedPrice: invoiceDetails.fixedPrice,
          workDescription: invoiceDetails.workDescription,
        });
        if (optionselect) {
          setInvoiceData((prevInvoiceData) => {
            const updatedInvoiceData = { ...prevInvoiceData };
    
            if (!updatedInvoiceData.invoiceAmount) {
              updatedInvoiceData.invoiceAmount = [];
            }
    
            updatedInvoiceData.invoiceAmount.push(newAmount);
    
            // Recalculate totalAmount
            const totalAmount = updatedInvoiceData.invoiceAmount.reduce(
              (acc, item) => acc + parseFloat(item.totalAmount || 0),
              0
            );
    
            updatedInvoiceData.invoice.totalAmount = totalAmount.toFixed(2);
    
            return updatedInvoiceData;
          });
        }
      }
      
      if(invSecOption){
        onInvoiceDetails(invoiceDetails);
      }
      console.log("Added Successfully");
      console.log(invoiceDetails);

      resetStates();
      setShowModal(false);
    } catch (error) {
      console.log("Failed to add amount", error);
    }
  };

  const handleDeleteInvoice = async () =>{
    try {
      const deleteConfirmation = window.confirm('Are you sure you want to delete?');
      if(deleteConfirmation){
        const response = await deleteInvoice(invoiceDetails.invoiceId);
        if(response){
          if(optionselect){
            setInvoiceData((prevInvoiceData) =>
              prevInvoiceData.filter((invoice) => invoice._id !== invoiceDetails.invoiceId)
              );
              navigate('/freelancer-view-invoices');
            console.log("Invoice deleted successfully");
          }
          if(invSecOption){
            navigate('/freelancer-view-invoices');
            console.log("Invoice deleted successfully");
          }
        }
        else{
          console.log("Error Occurred");
          appendAlert("Please try again later.", "danger");
        }
      }
    } catch (error) {
      console.log("Error Occurred");
      appendAlert("Please try again later.", "danger");
    }
  }

  const handleTrashInvoice = async () =>{
    let errorMessage = {};
    try {
      const deleteConfirmation = window.confirm('Are you sure you want to add it into the trash?');
      if(deleteConfirmation){
      if(invSecOption){
        console.log(allInvoiceDetails);
      }
      console.log(invoiceData);
      const response = await fetch(`${apiUrl}/api/invoice/sendtotrash`,{
        method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
            body: JSON.stringify({invoice: invoiceData.invoice, invoiceAmount: invoiceData.invoiceAmount}),
      })
      if (!response.ok) {
        errorMessage = await response.json();
        console.log(errorMessage.message)
        throw new Error("Network response was not ok");
      }
      console.log("Successfully sent invoice to trash")

        const deletedInvResponse = await deleteInvoice(invoiceDetails.invoiceId);
        if(deletedInvResponse){
          if(optionselect){
            setInvoiceData((prevInvoiceData) =>
              prevInvoiceData.filter((invoice) => invoice._id !== invoiceDetails.invoiceId)
              );
              navigate('/freelancer-view-invoices');
            console.log("Invoice deleted successfully");
          }
          if(invSecOption){
            navigate('/freelancer-view-invoices');
            console.log("Invoice deleted successfully");
          }
        }
        else{
          console.log("Error Occurred");
          appendAlert("Error while deleting. Please try again later.", "danger");
        }
        console.log("Invoice added into the trash Successfully.");
      
      }
      
    } catch (error) {
      appendAlert(errorMessage.message, "danger");
      console.log("Failed")
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(()=>{
    console.log(invoiceData)
  },[])
  const invoiceSendToAdmin = async () =>{
    setDisabledBtn(true)
    setLoading(true);
    let errorMessage = {};
    try {
      if(invSecOption){
        console.log(allInvoiceDetails);
      }
      console.log(invoiceData);
      const response = await fetch(`${apiUrl}/api/invoice/sentInvoices`,{
        method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
            body: JSON.stringify({invoice: invoiceData.invoice, invoiceAmount: invoiceData.invoiceAmount}),
      })
      if (!response.ok) {
        setDisabledBtn(false)
        setLoading(false);
        errorMessage = await response.json();
        console.log(errorMessage)
        console.log(errorMessage.message)
        throw new Error("Network response was not ok");
      }
    
      const newData = {
        invoice: invoiceData.invoice,
        clientData: invoiceData.client,
        invoiceAmount: invoiceData.invoiceAmount,
        userData: userProfile
      }
      //generating pdf email to admin
      const pdfResponse = await fetch(`https://globalworker-backend.vercel.app/api/admin/invoicepdf/generateinvoicepdfnew`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem('token'),
      },
        body: JSON.stringify({invoiceData: newData, totalAmountWithInterest: invoiceData.invoice.totalAmount, interest: 8}),
      });
      if (!pdfResponse.ok) {
        setDisabledBtn(false)
        setLoading(false);
        throw new Error("error while generating pdf");
      }

      const sendEmailResponse = await fetch(`https://globalworker-backend.vercel.app/api/admin/invoicepdf/sendinvoicepdftoadmin`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
            body: JSON.stringify({userData: userProfile, invoice: invoiceData.invoice})
          });
      
          if (!sendEmailResponse.ok) {
            setDisabledBtn(false)
            setLoading(false);
            throw new Error('Error while sending email with PDF attachment.');
          }
          
      setLoading(false);
      setDisabledBtn(false)
      appendAlert("Invoice sent Successfully.", "success");
      console.log("invoice and mail sent")
      setSentStatus(true);
      console.log('email sent successfully!!');

    } catch (error) {
      setDisabledBtn(false)
      setLoading(false);
      appendAlert(errorMessage.message, "danger");
      setTimeout(()=>{
        if(errorMessage.message === 'user profile does not exists. please setup your profile'){
          setLoading(false);
          navigate('/freelancer-user-profile')
        } else if(errorMessage.message === 'Bank information does not exists. please setup your bank information'){
          setLoading(false);
          navigate('/freelancer-bank-information')
        }
      }, 3000)
      console.log("Failed")
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleSubmitWithAgreement = async (e) =>{
    e.preventDefault();
    console.log("submitted"); 
    await invoiceSendToAdmin(e);
    setFirstInvoice(true);
  }

  const handleAgreementChecked = () =>{
    setAgreementChecked(true);
  }

  const radioHandle = (e) =>{
    setRadioClicked(e.target.value)
    console.log(e.target.value)
  }

    const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
    const appendAlert = (message, type) => {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('')
  
    alertPlaceholder.append(wrapper)
  }
    
    return (
      <div style={{ width: "80%", marginTop: "30px" }}>
        <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
        {loading && <Spinner/>}
      <div className="pb-5 create-invoice-btn-div">
        <button
          onClick={() => setShowModal(true)}
          type="button"
          className="create-invoice-btn"
        >
          Lägg till belopp
        </button>
      </div>
      {showModal && (
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header popup-blue-header">
                <h6 className="modal-title fs-5">Lägg till ett belopp på fakturan</h6>
                <button
                  type="button"
                  className="btn-close popup-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <form onSubmit={addAmount}>
                <div className="modal-body inv-popup-body">
                  <div className="row">
                    <div className="col">
                      <label
                        htmlFor="inv-units"
                        className="form-label form-lbl"
                      >
                        Enhet
                      </label>
                      <select
                        value={selectedUnit}
                        className="form-select"
                        id="inv-units"
                        onChange={unitOnChangeHandle}
                      >
                        <option value="Timmar">Timmar</option>
                        <option value="st">St.</option>
                        <option value="fast-pris">Fast Pris</option>
                      </select>
                      <label
                        htmlFor="inv-quantity"
                        className="form-label form-lbl mt-2"
                      >
                        Antal
                      </label>
                      <input
                        className="form-control"
                        id="inv-quantity"
                        placeholder="0-9"
                        required
                        value={hoursWorked}
                        onChange={onChangeHoursHandle}
                        disabled={disabledQuantityAndPrice}
                        style={{ backgroundColor: qtyAndPriceBgClr }}
                      />
                    </div>

                    <div className="col">
                      <label
                        htmlFor="inv-fixed-price"
                        className="form-label form-lbl"
                      >
                        Fast Pris
                      </label>
                      <input
                        type="number"
                        value={fixedPriceValue}
                        className={disabledBgColor}
                        id="inv-fixed-price"
                        disabled={disabledFixedPriceField}
                        onChange={onChangeFixedPriceHandle}
                        required
                      />
                      <label
                        htmlFor="inv-price"
                        className="form-label form-lbl mt-2"
                      >
                        Pris
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="inv-price"
                        required
                        step="0.01"
                        value={hourlyPrice}
                        onChange={onChangeHourlyPriceHandle}
                        disabled={disabledQuantityAndPrice}
                        style={{ backgroundColor: qtyAndPriceBgClr }}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="work-description"
                        className="form-label form-lbl mt-2"
                      >
                        Benämning / Arbetsbeskrivning / Typ av Jobb
                      </label>
                      <textarea
                        value={textAreaValue}
                        onChange={onChangeTextAreaHandle}
                        className="form-control"
                        id="work-description"
                        rows="3"
                        required
                      ></textarea>
                    </div>
                    <h5 className="inv-total-amount">{totalAmount} {invoiceData?.client?.privateClient?.pvtCurrencyCode} {invoiceData?.client?.organizationClient?.orgCurrencyCode}</h5>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary inv-save-btn"
                  >
                    Spara ändringar
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary inv-close-btn"
                    onClick={() => setShowModal(false)}
                  >
                    Stäng fönstret
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <div className="invoice-btn-grp-main mt-5">
      <div className="row invoice-btn-grp-div">
        {(!invSecOption && !firstInvoice) && <div className="col">
          {/* <button disabled={disabledBtn} onClick={invoiceSendToAdmin} className="invoice-btn-grp" id="inv-first-btn">
            {sentStatus ? 'Fakturan är Skickad' : 'Skicka Faktura'}
          </button> */}
           <button disabled={disabledBtn} data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="invoice-btn-grp" id="inv-first-btn">
            Skicka Faktura
          </button>
        </div>}
        <div className="col">
          <button onClick={()=>{navigate(`/generated-invoice/${invId || invoiceId}`)}} className="invoice-btn-grp" id="inv-second-btn">
            Förhandsvisning
          </button>
        </div>
        <div className="col">
          <button onClick={()=>{window.open(`/generated-salary-invoice/${invId || invoiceId}`,  '_blank')}} className="invoice-btn-grp" id="inv-third-btn">
          Lönespecifikation
          </button>
        </div>
        {/* <div className="col">
          <button onClick={handleDeleteInvoice} className="invoice-btn-grp" id="inv-fourth-btn">
            Radera
          </button>
        </div> */}
        {!invSecOption && <div className="col">
          <button onClick={handleTrashInvoice} className="invoice-btn-grp" id="inv-fifth-btn">
            Lägg i Papperskorgen
          </button>
        </div>}
      </div>
      </div>

      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel"><strong>Avtal!</strong></h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Klicka på länken nedan och läs avtalet</p>
              <Link onClick={()=>{handleAgreementChecked()}} to='https://globalworker.se/avtal/globalworker-medlemmar-av-org.pdf' target="_blank">https://globalworker.se/avtal/globalworker-medlemmar-av-org.pdf</Link>
            </div>
            <form className="modal-footer" onSubmit={handleSubmitWithAgreement} style={{justifyContent: 'center'}}>
              <div style={{width: '100%', display: 'flex'}}>
                <div className="form-check modal-footer-form">
                  <input className="form-check-input" onChange={radioHandle} value={true} type="radio" name="agreementRadio" id="agreementRadio" style={{border: '2px solid #b7a9a9', margin: '0px'}} required disabled={!agreementChecked}/>
                  <label className="form-check-label" htmlFor="agreementRadio">
                    Jag har läst hela avtalet
                  </label>
                </div>
              </div>
              <div className="">
                <button type="submit" data-bs-dismiss={radioClicked && "modal"} aria-label={radioClicked && "Close"} className="invoice-btn-grp" id="inv-first-btn" disabled={!radioClicked}>
                Skicka Faktura
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FreelancerCreateInvoice;
