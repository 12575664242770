import React, { useState, useEffect, useContext } from 'react';
import userContext from '../../context/UserContext';

const PaypalInfo = ({paypalEmail, setPaypalEmail}) => {
    const context = useContext(userContext);
    const { getBankInfoData, userBankingData } = context;

    useEffect(()=>{
        getBankInfoData();
      }, [])

      useEffect(() => {
        console.log(userBankingData);
      
        if (userBankingData && userBankingData.paypalInfo) {
            setPaypalEmail(userBankingData.paypalInfo.email || '');
        }
      }, [userBankingData]);

      const handleOnChangeEmail = (e) =>{
        setPaypalEmail(e.target.value);
      }

  return (
    <div className='fl-wise-account-form-container'>
      <h6 className='fl-wise-account-form-heading'>
      Var vänlig och skriv din E-postadress som är kopplad till ditt PayPal konto
      </h6>
      <div className="mb-3 mt-3 wise-bank-name-div">
        <label htmlFor="wise-bank-name-field" className="form-label">E-post <span className='red-sterik'>*</span></label>
        <input onChange={handleOnChangeEmail} style={{width: '60%'}} type="text" value={paypalEmail} className="form-control wise-bank-field" id="wise-bank-name-field"/>
    </div>
    </div>
  )
}

export default PaypalInfo
