import React, { useState } from 'react';
import userContext from "./UserContext";

const UserProvider = ({ children }) => {
    const userP = [];
    const [user, setUser] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userProfile, setUserProfile] = useState(userP);
    const [userBankingData, setUserBankingData] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [invoiceId, setInvoiceId] = useState(null);
    const [bankInfoModal, setBankInfoModal] = useState(false);
    const [dashboardModal, setDashboardModal] = useState(false);
    const [profileName, setProfileName] = useState("");
    const [changeInvoiceBtnTxt, setChangeInvoiceBtnTxt] = useState('');
    const apiUrl = 'https://globalworker-backend.vercel.app';

    const splitFullName = async (fullName) =>{
      const [first, last] = fullName.split(" ");
      setFirstName(first);
      setLastName(last);
    }

      const getUser = async () => {
        try {
          const response = await fetch(`${apiUrl}/api/auth/getuser`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'), // Add your access token if needed
            },
          });
          const userData = await response.json();
          await splitFullName(userData.name);
          console.log(userData)
          setUser(userData);
          console.log(firstName);
          
        } catch (error) {
          console.log("Error while getting user data")
        }
    };

    const getUserProfileData = async () =>{
      try {
        const response = await fetch(`${apiUrl}/api/userprofile/getuserprofile`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem('token'),
          },
        })
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const profileData = await response.json();
        
        if (profileData && profileData.profileOption) {
          setUserProfile(profileData);
          if(profileData.profileOption === 'private'){
            setProfileName(`${profileData.privateUserInfo.pvtFname} ${profileData.privateUserInfo.pvtLname}`);
          }
          if(profileData.profileOption === 'organization'){
            setProfileName(`${profileData.organizationUserInfo.organizationFname} ${profileData.organizationUserInfo.organizationLname}`);
          }
          console.log(profileData);
        }
        
      } catch (error) {
        console.error("Error while getting user profile:", error);
      }
    }

    const saveUserProfile = async (profileData) => {
        try {
          const response = await fetch(`${apiUrl}/api/userprofile/saveprofile`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'), // Add your access token if needed
            },
            body: JSON.stringify(profileData),
          });
          const userProfileData = await response.json();
          const success = true;
          return success;
        } catch (error) {
          console.error("Error saving private user profile:", error);
        }
      };

    const saveUserBankingInfo = async (bankData) => {
        try {
          console.log(bankData)
          const response = await fetch(`${apiUrl}/api/bankinformation/savebankinfo`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
            body: JSON.stringify(bankData),
          });
          if(!response.ok){
            throw new Error('Network response was not ok');
          }
          const userBankData = await response.json();
          const success = true;
          return success;
        } catch (error) {
          console.error("Error saving private user profile:", error);
        }
      };

      const getBankInfoData = async () => {
        try {
          const response = await fetch(`${apiUrl}/api/bankinformation/getbankinfo`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            }
          });
          if(!response.ok){
            throw new Error('Network response was not ok');
          }
          const bankInfoData = await response.json();
          console.log(bankInfoData)
          setUserBankingData(bankInfoData);

        } catch (error) {
          console.error("Error while getting bank info:", error);
        }
      };

      //Create Client 
      const createClient = async (client) => {
        try {
          const response = await fetch(`${apiUrl}/api/client/createclient`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
            body: JSON.stringify(client),
          })

        } catch (error) {
          console.error("Error while creating Client", error);
        }
      };

      //Fetch All the clients.
      const fetchAllClients = async () =>{
        try {
          const response = await fetch(`${apiUrl}/api/client/fetchallclients`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
          })
          if(!response.ok){
            throw new Error('Network response was not ok');
          }
          const allClientData = await response.json();
          console.log(allClientData.clients);
          setClientData(allClientData.clients);
        } catch (error) {
          console.error("Error while Fetching the Clients", error);
        }
      }

      const [currentClient, setCurrentClient] = useState(null); // State to hold the current client details

      const fetchClientById = async (clientId) => {
        try {
          const response = await fetch(`${apiUrl}/api/client/${clientId}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
          });
          if (!response.ok) {
            throw new Error(`Failed to fetch client with ID ${clientId}`);
          }
          const existingClient = await response.json();
          return existingClient;
        } catch (error) {
          console.error('Error fetching client:', error);
          throw error; // Propagate the error for further handling in the component
        }
      };

  // Function to fetch client by ID
      const getClientById = async (clientId) => {
        try {
          const existingClientData = await fetchClientById(clientId); 
          setCurrentClient(existingClientData);
        } catch (error) {
          console.error("Error fetching client:", error);
        }
      };

      const updateClient = async (clientId, updatedclient) =>{
        try {
          const response = await fetch(`${apiUrl}/api/client/updateclient/${clientId}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
            body: JSON.stringify(updatedclient),
          })
          if (!response.ok) {
            throw new Error(`Failed to update client with ID ${clientId}`);
          }
          setCurrentClient(updatedclient)
          console.log("Client Updated");
        } catch (error) {
          console.error("Error while updating the Clients", error);
        }
      }
      
      const deleteClient = async (clientId) =>{
        try {
          const response = await fetch(`${apiUrl}/api/client/deleteclient/${clientId}`,{
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
          })
          if (response.ok) {
            setClientData((prevClientData) =>
              prevClientData.filter((client) => client._id !== clientId)
            );
          } else {
            console.error("Error while deleting the client. Server returned:", response.statusText);
          }
        } catch (error) {
          console.error("Error while deleting the Client", error);
        }
      }

      //Create Invoice
      const createInvoice = async (invoice) =>{
        try {
          const response = await fetch(`${apiUrl}/api/invoice/createinvoice`,{
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
            body: JSON.stringify(invoice),
          })
          const invoiceData = await response.json();
          console.log(invoiceData)
          setInvoiceId(invoiceData)
          return invoiceData;
        } catch (error) {
          console.error("Error while creating Invoice", error);
        }
      }

      //Update Invoice
      const updateInvoice = async (invoiceId, invoice) =>{
        try {
          const response = await fetch(`${apiUrl}/api/invoice/updateinvoice/${invoiceId}`,{
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
            body: JSON.stringify(invoice),
          })
        } catch (error) {
          console.error("Error while updating Invoice", error);
        }

      }

      const deleteInvoice = async (id) =>{
        try {
          const response = await fetch(`${apiUrl}/api/invoice/deleteinvoice/${id}`,{
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
          })
          if (!response.ok) {
            throw new Error(`Failed to delete invoice`);
          }
          const success = true;
          return success;
        } catch (error) {
          console.error("Error while DELETING Invoice", error);
        }
      }

      const addInvoiceAmount = async (invoiceAmount) =>{
        try {
          const response = await fetch(`${apiUrl}/api/invoice/addamount`,{
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
            body: JSON.stringify(invoiceAmount),
          })
          if (!response.ok) {
            throw new Error(`Failed to add invoice amount`);
          }
          const newInvoiceAmount = await response.json();
          return newInvoiceAmount.data
        } catch (error) {
          console.error("Error while creating Invoice", error);
        }
      }
      
      const updateInvoiceAmount = async (invoiceAmountId, updatedAmount) =>{
        try {
          const response = await fetch(`${apiUrl}/api/invoice/updateamount/${invoiceAmountId}`,{
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
            body: JSON.stringify(updatedAmount),
          })
          if (!response.ok) {
            throw new Error(`Failed to update invoice amount with ID ${invoiceAmountId}`);
          }
          const updatedInvoiceAmount = await response.json();
          return updatedInvoiceAmount.data;
        } catch (error) {
          console.error("Error while updating amount", error);
        }
      }

      const deleteInvoiceAmount = async (id) =>{
        try {
          const response = await fetch(`${apiUrl}/api/invoice/deleteamount/${id}`,{
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
          })
          if (!response.ok) {
            throw new Error(`Failed to delete invoice amount with ID ${id}`);
          }
          console.log(`deleted ${id}`)
          const deletion = true;
          return deletion;
        } catch (error) {
          console.error("Error while deleting amount", error);
        }
      }

      const changePassword = async (data) =>{
        try {
          const response = await fetch(`${apiUrl}/api/auth/changepassword`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
            body: JSON.stringify(data),
          })
          if (!response.ok) {
            console.log(`Failed to update Password`);
            return {success: false, response: await response.json()}
          }
          const updPassRes = await response.json();
          const message = {success: true, response: updPassRes}
          return message;
        } catch (error) {
          console.error("Error while updating password", error);
        }
      }

  return (
    <userContext.Provider value={{user, setUser, getUser, saveUserProfile, userProfile, setUserProfile, getUserProfileData, getBankInfoData, userBankingData, setUserBankingData, saveUserBankingInfo, clientData, createClient, createInvoice, addInvoiceAmount, invoiceId, fetchAllClients, updateClient, getClientById, currentClient, updateInvoice, updateInvoiceAmount, deleteClient, deleteInvoiceAmount, deleteInvoice, profileName, setProfileName, changeInvoiceBtnTxt, setChangeInvoiceBtnTxt, changePassword, bankInfoModal, setBankInfoModal, dashboardModal, setDashboardModal, firstName, lastName}}>
      {children}
    </userContext.Provider>
  );
};

export default UserProvider;
