import React from 'react';
import '../styles/Layout.css'
import '../styles/Navbar.css'
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';

const Layout = ({ children, heading, btnStatus, setMWidth }) => {
  const navigate = useNavigate();
  return (
    <>
    <Navbar/>
      <div style={{maxWidth: setMWidth}} className="container text-center container-layout">
        <div className="blue-box-design"><button className='backBtn' onClick={()=>{navigate(-1)}}><img src={require('../assets/back-button.png')} alt="" /></button></div>
      <div style={{display: btnStatus}} className="heading-div">
        <h3 className="valuta-heading">{heading}</h3>
      </div>
        {children}
      </div>
    </>
  );
};

export default Layout;
