import React, { useState, useEffect } from "react";
import "../../styles/ResetPassword.css";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidToken, setIsValidToken] = useState('false');
  const { token } = useParams();
  const navigate = useNavigate();
  const apiUrl = 'https://globalworker-backend.vercel.app';
  useEffect(() => {
    document.title = 'Freelancer - Reset Password'
    const checkTokenValidity = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/auth/validate-token/${token}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          setIsValidToken('invalid');
          throw new Error("Token is not valid");
        }
        setIsValidToken('true');
      } catch (error) {
        setIsValidToken('invalid');
        console.error("Error validating token:", error);
      }
    };

    checkTokenValidity();
  }, [token]);

  const handleResetPassword = async (e) => {
      e.preventDefault()
    try {
      if (password === confirmPassword) {
        const response = await fetch(
          `${apiUrl}/api/auth/resetpassword?token=${token}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ password }),
          }
        );
        if (!response.ok) {
          throw new Error("Token is not valid");
        }
        console.log("Password changed successfully");
        navigate("/signin");
      } else {
        console.log("Password does not match");
        appendAlert("Password does not match.", "danger");
        return;
      }
    } catch (error) {
      console.error("Error please try again later:", error);
    }
  };

  const alertPlaceholder = document.getElementById("liveAlertPlaceholder");
  const appendAlert = (message, type) => {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      "</div>",
    ].join("");

    alertPlaceholder.append(wrapper);
  };

  return (
    <>
      {isValidToken === 'true' && (
        <>
          <div id="liveAlertPlaceholder"></div>
          <div className="container main-container-reset mb-4 mt-4">
            <div className="mb-4">
              <img
                src={require("../../assets/hela@10x-100.png")}
                alt="globalworkers"
              />
            </div>
            <form onSubmit={handleResetPassword} style={{ width: "40%" }} className="mt-4 reset-form">
              <h3 id="login-heading">Återställ lösenord</h3>
              <hr id="divider" />
              <label className="form-lebels" htmlFor="signin-email">
                Lösenord
              </label>
              <input
                type="password"
                id="signin-email"
                className="form-control mb-4 mt-4"
                placeholder="*********"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <label className="form-lebels" htmlFor="signin-Password">
                bekräfta lösenord
              </label>
              <input
                type="password"
                id="signin-Password"
                className="form-control mb-4 mt-4"
                placeholder="***********"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <button
                type="submit"
                className="btn btn-primary mt-3 mb-2"
                id="login-btn"
              >
                Skicka in
              </button>
            </form>
          </div>
        </>
      )} 
        {isValidToken === 'false' && <>
          <Spinner/>
        </>}
        {isValidToken === 'invalid' && <>
          <h1>Link has been Expired</h1>
        </>}
    </>
  );
};

export default ResetPassword;
