import React, { useEffect, useState, useContext } from 'react'
import Layout from '../../common/Layout'
import { Link, useParams } from 'react-router-dom';
import '../../styles/FreelancerInvoiceList.css';
import userContext from '../../context/UserContext';

function SpecificClientInvoices() {
    const {clientId} = useParams();
    const context = useContext(userContext);
    const {deleteInvoice} = context;
    const [clientInvoices, setClientInvoices] = useState([]);
    const apiUrl = 'https://globalworker-backend.vercel.app';
    useEffect(()=>{
      document.title = 'Freelancer - Client Invoices'
    const getClientInvoices = async () =>{
        console.log(clientId)
        try {
          const response = await fetch(`${apiUrl}/api/invoice/specificclientinvoices/${clientId}`, {
            method: "GET",
              headers: {
                "Content-Type": "application/json",
                "auth-token": localStorage.getItem('token'),
              },
          })
          if (!response.ok) {
            throw new Error('Failed to fetch Invoices');
          }
          const invoices = await response.json();
          setClientInvoices(invoices);
        } catch (error) {
          console.error('Error fetching client:', error);
          throw error;
        }
      }
  
      getClientInvoices();
    }, [])

    const handleDeleteInvoice = async (id) =>{
        try {
          const deleteConfirmation = window.confirm('Are you sure you want to delete?');
          if(deleteConfirmation){
            const response = await deleteInvoice(id);
            if(response){
              setClientInvoices((prevInvoiceData) =>
                prevInvoiceData.filter((invoice) => invoice._id !== id)
                );
              console.log("Invoice deleted successfully");
              appendAlert("Invoice Amount deleted Successfully.", "success");
            }
            else{
              console.log("Error Occurred");
              appendAlert("Please try again later.", "danger");
            }
          }
        } catch (error) {
          console.log("Error Occurred");
          appendAlert("Please try again later.", "danger");
        }
      }
    
      const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
      const appendAlert = (message, type) => {
        const wrapper = document.createElement('div')
        wrapper.innerHTML = [
          `<div class="alert alert-${type} alert-dismissible" role="alert">`,
          `   <div>${message}</div>`,
          '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
          '</div>'
        ].join('')
      
        alertPlaceholder.append(wrapper)
      }
  return (
    <Layout btnStatus="none" setMWidth="95%">
      <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
      <div
        className="heading-div bank-info-heading-div"
        style={{ width: "100%", marginLeft: "70px" }}>
        <h5 className="valuta-heading bank-info-heading">Fakturor</h5>
      </div>
      <div className="client-list-container">
        <div className="row client-list-row">
          <div className="col">
            <h6 className="bold-txt">Kund Namn</h6>
            {clientInvoices.map((data, index) =>(
              <div className='invoiceListRow' key={index}>
              <h6>{data.clientName}</h6>
              </div>
            ))}
          </div>
          <div className="col">
            <h6 className="bold-txt">belopp</h6>
            {clientInvoices.map((data, index) =>(
              <div className='invoiceListRow' key={index}>
              <h6>{data.totalAmount}</h6>
              </div>
            ))}
          </div>
          <div className="col">
            <h6 className="bold-txt">upphöra</h6>
            {clientInvoices.map((data, index) =>(
              <div className='invoiceListRow' key={index}>
                <h6>{data.dueDate}</h6>
              </div>
            ))}
          </div>
          <div className="col">
            <h6 className="bold-txt">Redigera</h6>
            {clientInvoices.map((data, index) => (
              <div className='invoiceListRow' key={index}>
                <Link style={{marginBottom: '0.5rem'}} to={`/freelancer-edit-invoice/${data._id}`}  className="editClientBtn" >
                Skicka Faktura
                </Link>
                |<Link style={{marginBottom: '0.5rem'}} to={`/generated-invoice/${data._id}`}>visa faktura</Link>
                {/* |<Link onClick={()=>{handleDeleteInvoice(data._id)}} style={{color: 'red'}} to={"#"}>Radera</Link> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SpecificClientInvoices
