import React, {useEffect, useState, useContext} from "react";
import "../../styles/GeneratedInvoice.css";
import userContext from "../../context/UserContext";
import { useParams, Link, useNavigate } from "react-router-dom";
import Spinner from "./Spinner";

const SalaryInvoice = () => {
    const navigate = useNavigate();
    const { invoiceId } = useParams();
    const context = useContext(userContext);
    const {user, getUser, getBankInfoData, userBankingData, profileName, setChangeInvoiceBtnTxt} = context;
    const [invoiceData, setInvoiceData] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [calculatedAmount, setCalculatedAmount] = useState(0);
    const [tAmount, setTAmount] = useState(0);
    const [percentageAmount, setPercentageAmount] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [loading, setLoading] = useState(true);
    const apiUrl = 'https://globalworker-backend.vercel.app';
    
    useEffect(() => {
      document.title = 'Freelancer - Salary Invoice'
      const getInvoiceData = async () => {
        // setLoading(true)
        try {
          const response = await fetch(
            `${apiUrl}/api/invoice/invoicedata/${invoiceId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "auth-token": localStorage.getItem('token'),
              },
            }
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          console.log(data)
          setInvoiceData(data);
          
          setTAmount(data.invoice.totalAmount);
          

          // const formattedAmount = new Intl.NumberFormat('en-US', {
          //   style: 'currency',
          //   currency: data.client?.privateClient?.pvtCurrencyCode || data.client?.organizationClient?.orgCurrencyCode,
          // }).format(result);

          // setCalculatedAmount(formattedAmount)

          // const rem = result % 100;
          // setPercentageAmount(rem);
          

        } catch (error) {
          console.error("Error while getting Client info:", error);
          
        }
      };

      getUser();
      getInvoiceData();
      getBankInfoData();
    }, []);

    useEffect(()=>{
      if(userBankingData.bankAndWiseOption === 'bankAccountInfo' && userBankingData.bankAccountInfo){
        setPercentage(8);
        console.log(userBankingData);
        const result = tAmount - (tAmount * 8) / 100;
        console.log(result);
        setCalculatedAmount(result);
        const rem = tAmount * (8 / 100);
        console.log(rem)
        setPercentageAmount(rem);
        setLoading(false)
      }
      if(userBankingData.bankAndWiseOption === 'wiseAccountInfo' && userBankingData.wiseAccountInfo){
        setPercentage(6);
        console.log(userBankingData);
        const result = tAmount - (tAmount * 6) / 100;
        console.log(result);
        setCalculatedAmount(result);
        const rem = tAmount * (6 / 100);
        console.log(rem)
        setPercentageAmount(rem);
        setLoading(false)
      }

      if(userBankingData.bankAndWiseOption === 'paypalInfo' && userBankingData.paypalInfo){
        if(tAmount > 800){
          setPercentage(10);
          console.log(userBankingData);
          const result = tAmount - (tAmount * 10) / 100;
          console.log(result);
          setCalculatedAmount(result);
          const rem = tAmount * (10 / 100);
          console.log(rem)
          setPercentageAmount(rem);
          setLoading(false)
        }
        else{
          setPercentage(12);
          console.log(userBankingData);
          const result = tAmount - (tAmount * 12) / 100;
          console.log(result);
          setCalculatedAmount(result);
          const rem = tAmount * (12 / 100);
          console.log(rem)
          setPercentageAmount(rem);
          setLoading(false)
        }
      }

    }, [invoiceData])
  
    return (
      <>
      {loading && <Spinner/>}
      {!loading && <>
        <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
    <div className="mt-3 buttonMainContainer">
      <div className="button-div">
        <button className="previewInvoiceButtons goBackBtn" onClick={()=>{navigate(-1); setChangeInvoiceBtnTxt('salarySpec')}}>Gå tillbaka</button>
        </div>
        </div> 
      <div className="invoiceBodyContainer"> <div className="generatedInvoiceContainer">
        <div className="mt-3 mb-2" style={{width: '80%', display: 'flex', justifyContent: 'end'}}>
        <table>
          <tbody>
          <tr>
            <th>Invoice No.</th>
            <td>{invoiceData?.invoice?.invoiceNumber}</td>
          </tr>
          </tbody>
        </table>
      </div>
        <div className="g-invoiceHeader">
          <img src={require("../../assets/hela@10x-100.png")} alt="noimgae" />
          <table className="table table-bordered g-invoiceHeader-table">
          <tbody>
            <tr>
              <th className="g-invoiceHeader-col">Date</th>
              <th className="g-invoiceHeader-col">Customer</th>
            </tr>
            <tr>
              <td className="g-invoiceHeader-col">{currentDate.toLocaleDateString()}</td>
              <td className="g-invoiceHeader-col">{invoiceData?.client?.customerNumber}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="mb-1 salaryNumber-container">
            <table>
                <tbody>
                    <tr>
                        <th>Salary:</th>
                        <td>{invoiceData?.invoice?.invoiceNumber}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="invoiceCustomerDetails mb-4 mt-2">
          <table className="invoiceCustomerDetailsTable" >
            <tbody>
            <tr>
              <th>Our Reference:</th>
              <td>{invoiceData?.invoice?.clientName}</td>
            </tr>
            <tr>
              <td>{profileName !== "" ? profileName : user.name}</td>
              <td>{invoiceData?.client?.clientOption === "private"
                  ? invoiceData?.client?.privateClient?.pvtStreet
                  : invoiceData?.client?.organizationClient?.orgStreet}
              </td>
            </tr>
            <tr>
              <td>{user.email}</td>
              <td>{`${invoiceData?.client?.clientOption === "private"
                    ? invoiceData?.client?.privateClient?.pvtPostalCode
                    : invoiceData?.client?.organizationClient?.orgPostalCode} 
                    ${invoiceData?.client?.clientOption === "private"
                    ? invoiceData?.client?.privateClient?.pvtCity
                    : invoiceData?.client?.organizationClient?.orgCity}`}</td>
            </tr>
            <tr>
              <th>Your Reference:</th>
              <td>{invoiceData?.client?.clientOption === "private"
                    ? invoiceData?.client?.privateClient?.pvtCountry
                    : invoiceData?.client?.organizationClient?.orgCountry}
              </td>
            </tr>
            <tr>
              <td>{invoiceData?.client?.clientOption === "private"
                    ? invoiceData?.client?.privateClient?.pvtReferenceName
                    : invoiceData?.client?.organizationClient?.orgReferenceName}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>Customer Email:</b> {invoiceData?.client?.clientOption === "private"
                    ? invoiceData?.client?.privateClient?.pvtRecipientEmail
                    : invoiceData?.client?.organizationClient?.orgRecipientEmail}
                    </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="invoiceAmountContainer mb-4">
          <table className="invoiceAmountTable table table-bordered">
          <tbody>
          <tr className="amountDataRow">
            <th className="data-1" colSpan="3">Work Description</th>
            <th className="data-12">Quantity</th>
            <th className="data-12">Unit</th>
            <th className="data-12">Price</th>
            <th className="data-14">Amount</th>
          </tr>
            {invoiceData?.invoiceAmount?.map((data, index) => (
              <tr key={index}>
                <td colSpan="3">{data.workDescription}</td>
                <td >{data.quantity}</td>
                <td >{data.unit}</td>
                <td >{data.fixedPrice == null ? data.price : data.fixedPrice}</td>
                <td >{data.totalAmount}</td>
              </tr>
            ))}
          <tr className="" >
            <th colSpan="4" className='clspan-4-th'></th>
            <th colSpan="2" className="pt-2" >Total invoice amount</th>
            <td className="pt-2">{invoiceData?.invoice?.totalAmount} {invoiceData?.client?.privateClient?.pvtCurrencyCode ||invoiceData?.client?.organizationClient?.orgCurrencyCode}</td>
          </tr>
          <tr className="">
            <th colSpan="4" className='clspan-4-th'></th>
            <th colSpan="2" className="" >Our service fee {percentage}%</th>
            <td className="">{percentageAmount.toFixed(2)} {invoiceData?.client?.privateClient?.pvtCurrencyCode || invoiceData?.client?.organizationClient?.orgCurrencyCode}</td>
          </tr>
          <tr className="">
            <th colSpan="4" className='clspan-4-th'></th>
            <th colSpan="2" className="" >Transaction fee</th>
            <td className="">0 {invoiceData?.client?.privateClient?.pvtCurrencyCode || invoiceData?.client?.organizationClient?.orgCurrencyCode}</td>
          </tr>
          <tr className="">
            <th colSpan="4" className='clspan-4-th'></th>
            <th colSpan="2" className="" >Tax payment fee</th>
            <td className="">0 {invoiceData?.client?.privateClient?.pvtCurrencyCode || invoiceData?.client?.organizationClient?.orgCurrencyCode}</td>
          </tr>
          <tr className="">
            <th colSpan="4" className='clspan-4-th'></th>
            <th colSpan="2" className="" >Total tax to pay</th>
            <td className="">0 {invoiceData?.client?.privateClient?.pvtCurrencyCode || invoiceData?.client?.organizationClient?.orgCurrencyCode}</td>
          </tr>
          <tr className="">
            <th colSpan="4" className='clspan-4-th'></th>
            <th colSpan="2" className="" >Net salary to pay</th>
            <td className="">{calculatedAmount} {invoiceData?.client?.privateClient?.pvtCurrencyCode ||invoiceData?.client?.organizationClient?.orgCurrencyCode}</td>
          </tr>
          </tbody>
        </table>
        </div>
        <div className="invoice-note-container">
        <p>
          <b>Note: Please add the invoice number: {invoiceData?.invoice?.invoiceNumber} in the reference section
          of your online payment
          </b></p>
        <p className="note-text-p"><b>We only accept EUR</b></p>
      </div>
      <div className="row invoice-bankInfo-container">
        <div className="col invoice-bankInfo-col-1">
          <h5>URL</h5>
          <Link className="globalWorkerUrl" to={'https://globalworker.se'}>https://globalworker.se</Link>
          <h5 className="mt-4">Mail</h5>
          <p>info@globalworker.se</p>
        </div>
        <div className="col">
          <table className="invoice-bankInfo-table">
            <tbody>
              <tr>
                {/* <th>BIC/SWIFT CODE</th> */}
                <td><b>BIC/SWIFT CODE:</b>  TRWIBEB1XXX</td>
              </tr>
              <tr>
                {/* <th>IBAN</th> */}
                <td><b>IBAN:</b>  BE09 9675 1244 0757</td>
              </tr>
              <tr>
                {/* <th>Bank Name</th> */}
                <td><b>Bank Name:</b>  Wise Europe SA</td>
              </tr>
              <tr>
                {/* <th>City</th> */}
                <td><b>City:</b>  BRUSSELS</td>
              </tr>
              <tr>
                {/* <th>Country</th> */}
                <td><b>Country:</b>  BELGIUM</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <footer className="address-footer">
        <p>GLOBAL FREELANCER WORKERS LTD</p>
        <p>STRON LEGAL CLUBHOUSE ST JAMES 8</p>
        <p>SW1Y 4JU, United Kingdom, Company No. 14534594</p>
        <p className="mt-3">&copy; 2024-2025 Globalworker. All rights reserved.</p>
      </footer>
      </div></div></>}
      </>
    );
}

export default SalaryInvoice
