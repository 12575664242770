import React, { useEffect, useContext } from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";
import userContext from '../../context/UserContext';
import { countries } from 'countries-list';

function FreelancerBankAccountForm({bankInputValues, setBankInputValues}) {
  const context = useContext(userContext);
  const navigate = useNavigate();
  const allCountries = Object.values(countries);
  allCountries.sort((a, b) => a.name.localeCompare(b.name));
  const { userBankingData, getBankInfoData } = context;
  const initialCountries = [
    'Sweden',
    'Iran',
    'Nordkorea',
    'Kuba',
    'Belarus',
    'Ryssland',
  ];

  useEffect(()=>{
    getBankInfoData()
  }, [])

  useEffect(() => {
    if (userBankingData && userBankingData.bankAccountInfo) {
      setBankInputValues({
        country: userBankingData?.bankAccountInfo?.country || 'Select Country',
        bankName: userBankingData?.bankAccountInfo?.bankName || '',
        accountNumber: userBankingData?.bankAccountInfo?.accountNumber || '',
        ibanNumber: userBankingData?.bankAccountInfo?.ibanNumber || '',
        swiftCode: userBankingData?.bankAccountInfo?.swiftCode || '',
        otherInfo: userBankingData?.bankAccountInfo?.otherInfo || '',
      });
    }
  }, [userBankingData, setBankInputValues]);

  const bankInfoOnHandle = (e) => {
    const { id, value } = e.target;
    if (id === 'bank-country-dropdown') {
      setBankInputValues((prev) => ({ ...prev, country: value }));
    } else if (id === 'bank-name-field') {
      setBankInputValues((prev) => ({ ...prev, bankName: value }));
    } else if (id === 'bank-accNo-field') {
      setBankInputValues((prev) => ({ ...prev, accountNumber: value }));
    } else if (id === 'bank-iban-field') {
      setBankInputValues((prev) => ({ ...prev, ibanNumber: value }));
    } else if (id === 'bank-swift-field') {
      setBankInputValues((prev) => ({ ...prev, swiftCode: value }));
    } else if (id === 'bank-otherInfo-field') {
      setBankInputValues((prev) => ({ ...prev, otherInfo: value }));
    }
  };

  return (
    <div className='fl-wise-account-form-container'>
      <h6 className='fl-wise-account-form-heading'>
        Vi kan för närvarande inte överföra pengartill Iran, Nordkorea, Kuba,
        Belarus, Ryssland.
      </h6>
      <h6 className='mt-3 mb-4 fl-wise-account-form-heading' style={{color: 'red', fontWeight: 'bold'}}>
      Fyll i dem nedanstående fält som erfordras för att du skall kunna erhålla dina pengar snabbast möjligt, är du osäker så kontakta din bank för korrekt information.
      </h6>
      <label className="form-check-label">
        Bankens Land <span className='red-sterik'>*</span>
      </label>
      <div className="mt-2">
        <select
          className="form-select wise-dropdown"
          value={bankInputValues.country}
          onChange={bankInfoOnHandle}
          id='bank-country-dropdown'
          required
        >
          <option defaultValue="Markera Landet" value="">
                  Markera Landet
                </option>
                {allCountries.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
        </select>
      </div>
      <div className=" mt-3 wise-bank-name-div">
        <label htmlFor="bank-name-field" className="form-label">
          Bankens namn (dit vi betalar din lön) <span className='red-sterik'>*</span>
        </label>
        <input
          type="text"
          value={bankInputValues.bankName}
          className="form-control wise-bank-field"
          id="bank-name-field"
          onChange={bankInfoOnHandle}
          required
        />
      </div>
      <div className="mt-3 wise-bank-name-div">
        <label htmlFor="bank-accNo-field" className="form-label">
          Ditt Kontonummer 
        </label>
        <input
          type='number'
          value={bankInputValues.accountNumber}
          onChange={bankInfoOnHandle}
          className="form-control wise-bank-field"
          id="bank-accNo-field"
          maxLength={32}
        />
      </div>
      <div className="mt-3 wise-bank-name-div">
        <label htmlFor="bank-iban-field" className="form-label">
          IBAN-nummer 
        </label>
        <input
          type='text'
          value={bankInputValues.ibanNumber}
          onChange={bankInfoOnHandle}
          className="form-control wise-bank-field"
          id="bank-iban-field"
          minLength={14} 
          maxLength={32}
        />
      </div>
      <div className="mt-3 wise-bank-name-div">
        <label htmlFor="bank-swift-field" className="form-label">
          BIC/Swift-kode 
        </label>
        <input
          type='text'
          value={bankInputValues.swiftCode}
          onChange={bankInfoOnHandle}
          className="form-control wise-bank-field"
          id="bank-swift-field"
        />
      </div>
      <div className="mt-4 wise-bank-name-div">
        <label htmlFor="bank-otherInfo-field" className="form-label bank-otherInfo-label">
        Fyll i detta fält om det finns några övriga uppgifter vi behöver för att kunna betala in pengarna till ditt bankkonto, annars lämnar du detta fält blankt.<br /><br />
        OBS: Om du jobbar utomlands och är skattskyldig i Sverige så fyller du i ditt fullständiga namn och personnummer i detta fält, för övrigt gäller all information under Alternativ 2. <br /><br />
        Du skapar och skickar fakturan som vanligt, skillnaden blir att innan din kund har fått den så skapar vi en kopia i Fortnox där vårt svenska företag Goveteran Support AB med bifirma Globalworker AB står som avsändare, här finns en länk till vårt svenska bolag.  
        </label>
        <textarea
          rows='4'
          cols='10'
          placeholder='(Valfritt)'
          value={bankInputValues.otherInfo}
          onChange={bankInfoOnHandle}
          className="form-control wise-bank-field"
          id="bank-otherInfo-field">
        </textarea>
        <div className="mt-4 CertificateBtn-div">
        <button type='button' className="CertificateBtn" onClick={()=>{window.open('https://globalworker.se/PDF/Registreringsbevis_559346-9694.pdf', '_blank')}}>REG-bevis</button>
      </div>
      <p className='bank-otherInfo-label mt-4'>När vi har skapat fakturan så skickar vi den till din kund och även till dig så att du vet vad som pågår, detta gör vi omgående efter att du har skapat din faktura.</p>
      </div>
    </div>
  );
}

export default FreelancerBankAccountForm;
