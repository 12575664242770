import React, { useState, useEffect, useContext } from 'react';
import userContext from '../../context/UserContext';

function FreelancerWiseAccountForm({ wiseInputValues, setWiseInputValues, selectedWiseCountry, wiseBankNameInput}) {
  const context = useContext(userContext);
  const { getBankInfoData, userBankingData } = context;

  useEffect(()=>{
    getBankInfoData();
  }, [])

  useEffect(() => {
    console.log(userBankingData);
  
    if (userBankingData && userBankingData.wiseAccountInfo) {
      setWiseInputValues({
        country: userBankingData.wiseAccountInfo.country || selectedWiseCountry,
        wiseBankName: userBankingData.wiseAccountInfo.wiseBankName || wiseBankNameInput,
        ibanNumber: userBankingData.wiseAccountInfo.ibanNumber || ''
      });
    }
  }, [userBankingData]);

  const ibanOnChangeHandle = (e) =>{
    setWiseInputValues({
      ...wiseInputValues,
      ibanNumber: e.target.value,
    });
  }

  return (
    <div className='fl-wise-account-form-container'>
      <h6 className='fl-wise-account-form-heading'>
        Vi kan för närvarande inte överföra pengar till Iran, Nordkorea, Kuba,
        Belarus, Ryssland.
      </h6>
      <label className="form-check-label">Bankens Land</label>
      <div className=" mt-2">
        <button
          className="btn btn-secondary dropdown-toggle wise-dropdown"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          disabled
        >
          {wiseInputValues.country}
        </button>
        <ul className="dropdown-menu">
          <li className="dropdown-item disabled" aria-disabled="true">
            <span>Belgium</span>
          </li>
        </ul>
      </div>
      <div className="mb-3 mt-3 wise-bank-name-div">
        <label htmlFor="wise-bank-name-field" className="form-label">Bankens namn (dit vi betalar din lön) <span className='red-sterik'>*</span></label>
        <input type="text" value={wiseInputValues.wiseBankName} className="form-control wise-bank-field" id="wise-bank-name-field" disabled/>
    </div>
    <div className="mb-3 mt-3 wise-bank-name-div">
        <label htmlFor="wise-iban-field" className="form-label">IBAN-nummer <span className='red-sterik'>*</span></label>
        <input type='text' value={wiseInputValues.ibanNumber} onChange={ibanOnChangeHandle} className="form-control wise-bank-field" id="wise-iban-field" minLength={14} maxLength={32} required/>
    </div>
    </div>
  );
}

export default FreelancerWiseAccountForm;
