import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const AuthRoute = (Component) => {
  const AuthenticatedComponent = (props) => {
    const isAuthenticated = localStorage.getItem('token');
    const location = useLocation();

    if (!isAuthenticated) {
      // Redirect to the login page if not authenticated
      return <Navigate to="/signin" />;
    }

    if(location.pathname === '/'){
      document.title = 'Freelancer - Dashboard'
    } 
    else if(location.pathname === '/freelancer-user-profile'){
      document.title = 'Freelancer - User Profile'
    }
    else if(location.pathname === '/freelancer-bank-information'){
      document.title = 'Freelancer - Bank Details'
    }
    else if(location.pathname === '/freelancer-create-client'){
      document.title = 'Freelancer - Create Client'
    }
    else if(location.pathname === '/freelancer-invoice'){
      document.title = 'Freelancer - Invoice'
    }
    else if(location.pathname === '/freelancer-recruit'){
      document.title = 'Freelancer - Recruit Members'
    }
    else if(location.pathname === '/freelancer-view-clients'){
      document.title = 'Freelancer - View Clients'
    }
    else if(location.pathname === '/freelancer-view-invoices'){
      document.title = 'Freelancer - View Invoices'
    }

    // Render the protected component if authenticated
    return <Component {...props} />;
  };

  return AuthenticatedComponent;
};

export default AuthRoute;