import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../common/Navbar";
import "../../styles/Dashboard.css";
import userContext from "../../context/UserContext";
import Spinner from "./Spinner";
function FreelancerDashboard() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false); 
  const [showBankModal, setShowBankModal] = useState(false); 
  const context = useContext(userContext);
  const { getBankInfoData, setChangeInvoiceBtnTxt, getUserProfileData, userProfile, getUser, user, dashboardModal, setDashboardModal} = context;
  const [dashboardLoading, setDashboardLoading] = useState(true)
  const apiUrl = 'https://globalworker-backend.vercel.app';


  const getUserProfileNew = async () =>{
    try {
      const response = await fetch(`${apiUrl}/api/userprofile/getuserprofile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem('token'),
        },
      })
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const profileData = await response.json();
      console.log(profileData)
      if(profileData === null){
        setShowModal(true)
        setDashboardLoading(false)
      } else{
        const bankResponse = await fetch(`${apiUrl}/api/bankinformation/getbankinfo`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem('token'),
          }
        });
        if(!bankResponse.ok){
          throw new Error('Network response was not ok');
        }
        const bankInfoData = await bankResponse.json();
        console.log(bankInfoData)
        if(profileData.profileStatus && bankInfoData === null){
          setShowBankModal(true)
          setDashboardLoading(false)
        }
      }


      setDashboardLoading(false)
    } catch (error) {
      console.error("Error while getting user profile:", error);
      setDashboardLoading(false)
    }
  }
  useEffect(() => {
    getUser();
    getUserProfileNew()
  }, []);

  return (
    <>
      {dashboardLoading ? <Spinner/> : <><Navbar />
      <div>
        {/* <div style={{position: 'sticky', top: '78px', textAlign: 'center'}} className="alert alert-primary alert-dismissible fade show" role="alert">
          <strong>Välkommen!</strong> Skapa Kund och Fakturera gör du i denna ordning.<br /> <p style={{color: 'red', fontWeight: 'bold'}}>{`Steg 1. Skapa Ny Kund -> Steg 2. Skapa Ny Faktura -> Steg 3. Förhandsgranska Faktura`}</p>
          <p>Om du är nöjd med din faktura när du förhandsgranskar så klickar du på knappen ”Skicka Faktura”, annars klickar du på knappen ”Redigera”. När du har skickat din faktura så får vi ett mejl, vi granskar din faktura inom 30 minuter och skickar den till din kund om allt är ok, annars skickar vi den tillbaka till dig med text om vilket som är fel så att du kan redigera fakturan.</p>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div> */}
      <div
        className="container text-center mt-4"
        style={{ paddingTop: "60px" }}
      >
        {/* <h1 id="dashboard-heading" className="">
          Kontrollpanelen
        </h1> */}
        <div className="row">
          <div className="col">
            <Link className="btn-heading" to={"/freelancer-user-profile"}>
              <button className="dashboard-btn btn btn-primary mt-4 mb-5">
                Mina Kontaktuppgifter
                <img
                  id="land-img"
                  src={require("../../assets/Recruit icon.png")}
                  alt="Image"
                />
              </button>
            </Link>
            <Link className="btn-heading" to={"/freelancer-invoice"}>
              <button className="dashboard-btn btn btn-primary mb-5">
                Skapa ny faktura
                <img
                  id="invoice-img"
                  src={require("../../assets/Invoice icon (1).png")}
                  alt="Image"
                />
              </button>
            </Link>
            <Link
              className="btn-heading"
              id="clientList"
              to={"/freelancer-view-clients"}
            >
              <button className="dashboard-btn btn btn-primary">
                Visa kunder
                <img
                  id="recruit-img"
                  src={require("../../assets/Tender icon.png")}
                  alt="Image"
                />
              </button>
            </Link>
          </div>
          <div className="col">
            <Link
              className="btn-heading mt-4 mb-5"
              to={"/freelancer-bank-information"}
            >
              <button className="dashboard-btn btn btn-primary">
                Mina Bankupgifter
                <img src={require("../../assets/Bank icon.png")} alt="Image" />
              </button>
            </Link>
            <Link className="btn-heading" to={"/freelancer-view-invoices"}>
              <button onClick={()=>{setChangeInvoiceBtnTxt('previewInvoice')}} className="dashboard-btn btn btn-primary">
              Förhandsgranska Faktura
                <img
                  id="invoice-img"
                  src={require("../../assets/Invoice icon (1).png")}
                  alt="Image"
                />
              </button>
            </Link>

            <Link className="btn-heading" to={"/freelancer-view-invoices/salary-spec"}>
              <button onClick={()=>{setChangeInvoiceBtnTxt('salarySpec')}} className="dashboard-btn btn btn-primary mt-5">
              Förhandsgranska lönespecar
                <img
                  id="invoice-img"
                  src={require("../../assets/Invoice icon (1).png")}
                  alt="Image"
                />
              </button>
            </Link>
            
            
          </div>
          <div className="col">
            <Link className="btn-heading" to={"/freelancer-create-client"}>
              <button className="dashboard-btn btn btn-primary mt-4 mb-5">
                Skapa ny kund
                <img
                  id="recruit-img"
                  src={require("../../assets/Tender icon.png")}
                  alt="Image"
                />
              </button>
            </Link>
            
            <Link className="btn-heading" to={"/freelancer-view-invoices/edit"}>
              <button onClick={()=>{setChangeInvoiceBtnTxt('editInvoice')}} className="dashboard-btn btn btn-primary">
                Mina Fakturor 
                <img
                  id="invoice-img"
                  src={require("../../assets/Invoice icon (1).png")}
                  alt="Image"
                />
              </button>
            </Link>
            <Link
              className="btn-heading"
              id="clientList"
              to={"/change-password"}
            >
              <button className="dashboard-btn btn btn-primary mt-5">
              Ändra lösenord
                <img
                  id="recruit-img"
                  src={require("../../assets/Settings Icon.png")}
                  alt="Image"
                />
              </button>
            </Link>
          </div>
          <div className="dash-img">
            <img src={require("../../assets/Clip_path_group-new.png")} alt="" />
          </div>
        </div>
      </div>
      </div>
      {showModal && (
        <div
          className="modal"
          tabIndex="-1"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Hej, {user?.name}</h5>
              </div>
              <div className="modal-body">
                <p>Välkommen till vårt fakturaverktyg. Först behöver du fylla i dina kontaktuppgifter. När dessa är sparade kan du gå vidare till nästa steg för att fylla i dina bank- och utbetalningsuppgifter. När även dessa är sparade, kommer du att hamna på huvudsidan i kontrollpanelen där du kan börja skapa kunder och fakturor. Dessa uppgifter används för fakturareferenser och löneutbetalning. <br />
                Var vänlig och dubbelkolla allt innan du klickar på "Spara" för att säkerställa att allt är korrekt, så att din lön kan betalas ut i tid utan några förseningar. <br />
                Fälten ”Förnamn” och ”Efternamn” skall endast innehålla det som fälten syftar på, inget annat, om inte en faktura är rätt ifylld vid vår granskning så skickas den tillbaka till dig för redigering. <br /> 
                Ju noggrannare du är med din faktura, desto snabbare har du dina pengar på ditt konto.</p>
              </div>
              <div className="modal-footer">
                <button type="button" onClick={()=>{navigate('/freelancer-user-profile')}} className="btn btn-primary">
                Fortsätta
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showBankModal && (
        <div
          className="modal"
          tabIndex="-1"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Du är ett steg bort!</h5>
              </div>
              <div className="modal-body">
                <p>fortsätt att ställa in dina bankuppgifter</p>
              </div>
              <div className="modal-footer">
                <button type="button" onClick={()=>{navigate('/freelancer-bank-information')}} className="btn btn-primary">
                Fortsätta
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {dashboardModal && (
        <div
        className="modal"
        tabIndex="-1"
        style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Välkommen!</h5>
            </div>
            <div className="modal-body">
              <p>Du har slutfört din profil framgångsrikt. Du har nu tillgång till fakturaverktyget.</p>
              <p>Skapa Kund och Fakturera gör du i denna ordning.</p>
              <p style={{color: 'red', fontWeight: 'bold'}}>{`Steg 1. Skapa Ny Kund -> Steg 2. Skapa Ny Faktura -> Steg 3. Förhandsgranska Faktura`}</p>
          <p>Om du är nöjd med din faktura när du förhandsgranskar så klickar du på knappen ”Skicka Faktura”, annars klickar du på knappen ”Redigera”. När du har skickat din faktura så får vi ett mejl, vi granskar din faktura inom 30 minuter och skickar den till din kund om allt är ok, annars skickar vi den tillbaka till dig med text om vilket som är fel så att du kan redigera fakturan.</p>
            </div>
            <div className="modal-footer">
            <button type="button" onClick={()=>{setDashboardModal(false)}} className="btn btn-secondary" data-bs-dismiss="modal">Stänga</button>
            </div>
          </div>
        </div>
      </div>
      )}
      
      </>}
    </>
  );
}
export default FreelancerDashboard;
