import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../common/Layout';
import Spinner from './Spinner';
import '../../styles/ChangePassword.css';
import userContext from '../../context/UserContext';
const ChangePassword = () => {
    const context = useContext(userContext)
    const {getUser, user, changePassword} = context;
    const [loading, setLoading] = useState(true)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [currentPassword, setCurrentPassword] = useState("")

    useEffect(()=>{
        getUser();
        setLoading(false);
    }, [])

    const handleChangePassword = async (e) =>{
        console.log(user.email)
        setSubmitLoading(true)
        e.preventDefault();
        try {
            const res = await changePassword({email: user.email, oldPassword: currentPassword, newPassword: password, confirmPassword: confirmPassword});
            if(res.success){
                console.log('Password Updated successfully')
                appendAlert(res.response.message, "success");
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setSubmitLoading(false);
                setPassword("")
                setConfirmPassword("")
                setCurrentPassword("")
            }
            if(!res.success){
                console.log(res.response);
                appendAlert(res.response.message, "danger");
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setSubmitLoading(false)
            }
        } catch (error) {
            console.log("Error while updating password")
            setSubmitLoading(false)
        }
    }

    const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
  const appendAlert = (message, type) => {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('')
  
    alertPlaceholder.append(wrapper)
  }
  return (
    <Layout btnStatus="none" setMWidth="95%">
      <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
      {loading && <Spinner/>}
      {!loading && <>
      <div className="main-container-fl pass-main-cont">
        <div className="left-container-fl pass-left-cont" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <form onSubmit={handleChangePassword} style={{ width: "60%" }} className="mt-4 reset-form">
        <h3 className="valuta-heading mb-4">Ändra lösenord</h3>
              <hr id="divider" style={{width: '100%'}}/>
              <label className="form-lebels" htmlFor="signin-currentpassword">
              Nuvarande lösenord
              </label>
              <input
                type="password"
                id="signin-currentpassword"
                className="form-control mb-4 mt-4"
                placeholder="*********"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />
              <label className="form-lebels" htmlFor="signin-newpass">
                Lösenord
              </label>
              <input
                type="password"
                id="signin-newpass"
                className="form-control mb-4 mt-4"
                placeholder="*********"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <label className="form-lebels" htmlFor="signin-Password">
                bekräfta lösenord
              </label>
              <input
                type="password"
                id="signin-Password"
                className="form-control mb-4 mt-4"
                placeholder="***********"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <button
                type="submit"
                className="btn btn-primary mt-3 mb-2"
                id="login-btn"
              >
                Ändra lösenord
              </button>
              <div className='mt-2' style={{width: '100%'}}>
                {submitLoading && <Spinner/>}
              </div>
            </form>
        </div>
        <div className="right-container-fl">
          <img src={require("../../assets/Element (1).png")} alt="no img" />
        </div>
      </div></>}
    </Layout>
  )
}

export default ChangePassword
