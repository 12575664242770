import React, {useState, useEffect, useContext} from 'react'
import userContext from "../../context/UserContext";
import Layout from '../../common/Layout';
import EditPrivateClientForm from './EditPrivateClientForm';
import EditOrganizationClientForm from './EditOrganizationClientForm';
import { useParams } from "react-router-dom";
function FreelancerEditClient() {
    const context = useContext(userContext);
  const { currentClient, getClientById, updateClient } = context;
  const { clientId } = useParams();

  useEffect(() => {
    document.title = 'Freelancer - Edit Client'
  }, []);

  useEffect(() => {
    getClientById(clientId);
    console.log(currentClient);
  }, [clientId]);

    const [selectedOption, setSelectedOption] = useState("private");
    const [privateClientInfo, setPrivateClientInfo] = useState({
    pvtIdNumber: '',
    pvtFirstName: '',
    pvtLastName: '',
    pvtRecipientEmail: '',
    pvtReferenceName: '',
    pvtContactNumber: '',
    pvtCurrencyCode: 'Markera Valuta',
    pvtStreet: '',
    pvtPostalCode: '',
    pvtCity: '',
    pvtCountry: 'Markera Landet',
    });

    const [organizationClientInfo, setOrganizationClientInfo] = useState({
    orgIdNumber: '',
    orgVATNumber: '',
    orgFirstName: '',
    orgLastName: '',
    orgRecipientEmail: '',
    orgReferenceName: '',
    orgContactNumber: '',
    orgCurrencyCode: '',
    orgStreet: '',
    orgPostalCode: '',
    orgCity: '',
    orgCountry: '',
    });

    useEffect(() => {
        if (currentClient) {
          setSelectedOption(currentClient.clientOption || "private");
      
          if (currentClient.clientOption === "private") {
            setPrivateClientInfo(currentClient.privateClient || {});
            setOrganizationClientInfo({
              orgIdNumber: '',
              orgVATNumber: '',
              orgFirstName: '',
              orgLastName: '',
              orgRecipientEmail: '',
              orgReferenceName: '',
              orgContactNumber: '',
              orgCurrencyCode: '',
              orgStreet: '',
              orgPostalCode: '',
              orgCity: '',
              orgCountry: '',
            });
          } else if (currentClient.clientOption === "organization") {
            setPrivateClientInfo({
              pvtIdNumber: '',
              pvtFirstName: '',
              pvtLastName: '',
              pvtRecipientEmail: '',
              pvtReferenceName: '',
              pvtContactNumber: '',
              pvtCurrencyCode: 'Markera Valuta',
              pvtStreet: '',
              pvtPostalCode: '',
              pvtCity: '',
              pvtCountry: 'Markera Landet',
            });
            setOrganizationClientInfo(currentClient.organizationClient || {});
          }
        }
      }, [currentClient]);
    const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    };

    const onChangeHandle = (e) => {
    setPrivateClientInfo((prev) => {
    let helper = { ...prev };
    helper[`${e.target.id}`] = e.target.value;
    return helper; 
    });
    };

    const onChangeOrgHandle = (e) => {
    setOrganizationClientInfo((prev) => {
    let helper = { ...prev };
    helper[`${e.target.id}`] = e.target.value;
    return helper; 
    });
    };

    const onChangePvtMobNo = (e) =>{
      let inputValue = e.target.value;
      let sanitizedValue = inputValue.replace(/[^+\d-]/g, ''); // Remove anything that is not a plus symbol, digit, or hyphen
  
      // Check if there's a plus symbol not at the start and remove it
      if (sanitizedValue.startsWith('+')) {
        sanitizedValue = '+' + sanitizedValue.slice(1).replace(/\+/g, '');
      } else {
        sanitizedValue = sanitizedValue.replace(/\+/g, '');
      }
  
      setPrivateClientInfo((prev) => ({
        ...prev,
        pvtContactNumber: sanitizedValue
      }));
    }
  
    const onChangeOrgMobNo = (e) =>{
      let inputValue = e.target.value;
      let sanitizedValue = inputValue.replace(/[^+\d-]/g, ''); // Remove anything that is not a plus symbol, digit, or hyphen
  
      // Check if there's a plus symbol not at the start and remove it
      if (sanitizedValue.startsWith('+')) {
        sanitizedValue = '+' + sanitizedValue.slice(1).replace(/\+/g, '');
      } else {
        sanitizedValue = sanitizedValue.replace(/\+/g, '');
      }
  
      setOrganizationClientInfo((prev) => ({
        ...prev,
        orgContactNumber: sanitizedValue
      }));
    }


  const handleSave = async (e) => {
    e.preventDefault();
    try {
        if(selectedOption === "private"){
            console.log(privateClientInfo)
            await updateClient(clientId, {clientOption: selectedOption, privateClient: privateClientInfo})
            setOrganizationClientInfo({
                orgIdNumber: '',
                orgVATNumber: '',
                orgFirstName: '',
                orgLastName: '',
                orgRecipientEmail: '',
                orgReferenceName: '',
                orgContactNumber: '',
                orgCurrencyCode: '',
                orgStreet: '',
                orgPostalCode: '',
                orgCity: '',
                orgCountry: '',
              });
        }
        else if(selectedOption === "organization"){
            console.log(organizationClientInfo)
            await updateClient(clientId, {clientOption: selectedOption, organizationClient: organizationClientInfo})
            setPrivateClientInfo({
                pvtIdNumber: '',
                pvtFirstName: '',
                pvtLastName: '',
                pvtRecipientEmail: '',
                pvtReferenceName: '',
                pvtContactNumber: '',
                pvtCurrencyCode: 'Markera Valuta',
                pvtStreet: '',
                pvtPostalCode: '',
                pvtCity: '',
                pvtCountry: 'Markera Landet',
              });
        }
      console.log("Updated Successfully");
      appendAlert("Client updated successfully.", "success");
    } catch (error) {
      console.log("Error Occurred", error);
      appendAlert("Error while updating Client.", "danger");
    }
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
  const appendAlert = (message, type) => {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('')
  
    alertPlaceholder.append(wrapper)
  }

  return (
    <Layout btnStatus="none" setMWidth="95%">
      <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
      <div id="createClientAlert" style={{width: '100%'}}></div>
      <div className="heading-div" style={{width: '100%', marginLeft: '70px'}}>
        <h5 className="valuta-heading fl-create-client-heading">Skapa/Redigera Privatkund eller Företagskund</h5>
      </div>
      <form onSubmit={handleSave} className="main-container-fl">
        <div className="">
          <div className="user-prof-radio-container">

          <div className="företags-kund-container" style={{marginRight: '30px'}}>
              <h6 className="företags-kund-heading">Företagskund</h6>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="organization"
                  checked={selectedOption === "organization"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Företag
                </label>
              </div>
            </div>

            <div className="Privatpersons-container">
              <h6 className="Privatpersons-heading">Privatkund</h6>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="private"
                  checked={selectedOption === "private"}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Privatperson
                </label>
              </div>
            </div>
          </div>
          <div>
            {selectedOption === "private" && (
              <EditPrivateClientForm onChange={onChangeHandle} values={privateClientInfo} onChangePvtMobNo={onChangePvtMobNo}/>
            )}
            {selectedOption === "organization" && (
              <EditOrganizationClientForm onChange={onChangeOrgHandle} onChangeOrgMobNo={onChangeOrgMobNo} values={organizationClientInfo}/>
            )}
            <div className="user-profile-save-btn-div mb-4">
                <button type="submit" className='btn btn-primary user-profile-save-btn'>Skapa/spara</button>
            </div>
          </div>
        </div>
      </form>
    </Layout>
  )
}

export default FreelancerEditClient
