import React, { useState, useContext, useEffect } from 'react';
import userContext from '../../context/UserContext';
import { countries } from 'countries-list';

function FreelancerTaxForm({wePayYourTaxInputValues, setWePayYourTaxInputValues}) {
      const context = useContext(userContext);
      const { userBankingData, getBankInfoData } = context;
      const allCountries = Object.values(countries);
      const initialCountries = [
        'Sweden',
        'Iran',
        'Nordkorea',
        'Kuba',
        'Belarus',
        'Ryssland',
      ];

      useEffect(()=>{
        getBankInfoData()
      }, [])

      useEffect(()=>{
        setWePayYourTaxInputValues({
          country: userBankingData?.wePayYourTax?.country || "",
          taxPercentage:userBankingData?.wePayYourTax?.taxPercentage || "",
          taxNumber: userBankingData?.wePayYourTax?.taxNumber || "",
          otherTaxInfo: userBankingData?.wePayYourTax?.otherTaxInfo || ""
        })
      }, [userBankingData, setWePayYourTaxInputValues])

    
      const taxInputOnChangeHandle = (e) => {
        const { id, value } = e.target;
        if (id === 'tax-country-dropdown') {
          setWePayYourTaxInputValues((prev) => ({ ...prev, country: value }));
        } else if (id === 'tax-percentage-field') {
          setWePayYourTaxInputValues((prev) => ({ ...prev, taxPercentage: value }));
        } else if (id === 'tax-number-field') {
          setWePayYourTaxInputValues((prev) => ({ ...prev, taxNumber: value }));
        } else if (id === 'other-taxInfo-field') {
          setWePayYourTaxInputValues((prev) => ({ ...prev, otherTaxInfo: value }));
        }
      };
  
  return (
    <div className='fl-wise-account-form-container'>
      <h6 className='fl-wise-account-form-heading tax-heading-fl'>
            Nedanstående fält ifylles endast om vi skall betala in din skatt
      </h6>
      <label className="form-check-label">
            Till vilket land skall Vi betalar in din skatt, gäller endast dig som är skattskyldig i Sverige
 <span className='red-sterik'>*</span>
      </label>
      <div className="mt-2">
      <select
          className="form-select wise-dropdown"
          value={wePayYourTaxInputValues.country}
          onChange={taxInputOnChangeHandle}
          id='tax-country-dropdown'
          required
        >
          <option defaultValue="Markera Landet" value="">
                  Markera Landet
                </option>
                {allCountries.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
        </select>
      </div>
      <div className="mb-3 mt-3 wise-bank-name-div">
        <label htmlFor="tax-percentage-field" className="form-label">
            Hur mycket skatt i procent skall dras av från din lön
        </label>
        <input
          type='number'
          value={wePayYourTaxInputValues.taxPercentage}
          className="form-control wise-bank-field"
          id="tax-percentage-field"
          onChange={taxInputOnChangeHandle}
          required
        />
      </div>
      <div className="mb-3 mt-3 wise-bank-name-div">
        <label htmlFor="tax-number-field" className="form-label">
            Ditt personnummer/skattenummer i landet där du är skriven
        </label>
        <input
          type='text'
          value={wePayYourTaxInputValues.taxNumber}
          onChange={taxInputOnChangeHandle}
          className="form-control wise-bank-field"
          id="tax-number-field"
          required
        />
      </div>
      <div className="mb-3 mt-3 wise-bank-name-div">
        <label htmlFor="other-taxInfo-field" className="form-label">
        Övriga uppgifter vi behöver för att kunna betala in din skatt
        </label>
        <input
          type='text'
          value={wePayYourTaxInputValues.otherTaxInfo}
          onChange={taxInputOnChangeHandle}
          className="form-control wise-bank-field"
          id="other-taxInfo-field"
        />
      </div>
    </div>
  )
}

export default FreelancerTaxForm
