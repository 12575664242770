import React, {useEffect} from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/Currency.css'
import './styles/CompanyInformation.css'
import Footer from './freelancer/components/Footer'
import FreelancerSignin from './freelancer/components/FreelancerSignin';
import FreelancerSignup from './freelancer/components/FreelancerSignup';
import FreelancerDashboard from './freelancer/components/FreelancerDashboard';
import FreelancerUserProfile from './freelancer/components/FreelancerUserProfile';
import FreelancerBankInfo from './freelancer/components/FreelancerBankInfo';
import FreelancerCreateClient from './freelancer/components/FreelancerCreateClient';
import FreelancerInvoiceSection from './freelancer/components/FreelancerInvoiceSection';
import FreelancerRecruitMemberSection from './freelancer/components/FreelancerRecruitMemberSection';
import UserProvider from './context/UserProvider';
import FreelancerClientList from './freelancer/components/FreelancerClientList';
import FreelancerEditClient from './freelancer/components/FreelancerEditClient';
import FreelancerInvoiceList from './freelancer/components/FreelancerInvoiceList';
import FreelancerEditInvoice from './freelancer/components/FreelancerEditInvoice';
import ClientGeneratedInvoice from './freelancer/components/ClientGeneratedInvoice';
import SpecificClientInvoices from './freelancer/components/SpecificClientInvoices';
import AuthRoute from './freelancer/components/AuthRoute';
import SalaryInvoice from './freelancer/components/SalaryInvoice';
import ResetPassword from './freelancer/components/ResetPassword';
import AuthCheck from './freelancer/components/AuthCheck';
import ChangePassword from './freelancer/components/ChangePassword';

const App = () => {
  const AuthenticatedFreelancerDashboard = AuthRoute(FreelancerDashboard);
  const AuthenticatedFreelancerUserProfile = AuthRoute(FreelancerUserProfile);
  const AuthenticatedFreelancerBankInfo = AuthRoute(FreelancerBankInfo);
  const AuthenticatedFreelancerCreateClient = AuthRoute(FreelancerCreateClient);
  const AuthenticatedFreelancerInvoiceSection = AuthRoute(FreelancerInvoiceSection);
  const AuthenticatedFreelancerRecruitMemberSection = AuthRoute(FreelancerRecruitMemberSection);
  const AuthenticatedFreelancerClientList = AuthRoute(FreelancerClientList);
  const AuthenticatedFreelancerEditClient = AuthRoute(FreelancerEditClient);
  const AuthenticatedFreelancerInvoiceList = AuthRoute(FreelancerInvoiceList);
  const AuthenticatedFreelancerEditInvoice = AuthRoute(FreelancerEditInvoice);
  const AuthenticatedSpecificClientInvoices = AuthRoute(SpecificClientInvoices);
  const AuthenticatedClientGeneratedInvoice = AuthRoute(ClientGeneratedInvoice);
  const AuthenticatedSalaryGeneratedInvoice = AuthRoute(SalaryInvoice);
  const AuthenticatedSignin = AuthCheck(FreelancerSignin);
  const AuthenticatedSignup = AuthCheck(FreelancerSignup);
  const AuthenticatedChangePassword = AuthCheck(ChangePassword);

  return (
    <UserProvider>
    <Router>
      <Routes>
        <Route exact path="/signin" element={<AuthenticatedSignin/>} ></Route>
        <Route exact path="/signup" element={<AuthenticatedSignup/>} ></Route>
        
        <Route exact path="/" element={<AuthenticatedFreelancerDashboard />} ></Route>
        <Route exact path="/freelancer-user-profile" element={<AuthenticatedFreelancerUserProfile />} ></Route>
        <Route exact path="/freelancer-bank-information" element={<AuthenticatedFreelancerBankInfo/>} ></Route>
        <Route exact path="/freelancer-create-client" element={<AuthenticatedFreelancerCreateClient/>} ></Route>
        <Route exact path="/freelancer-invoice" element={<AuthenticatedFreelancerInvoiceSection/>} ></Route>
        <Route exact path="/freelancer-recruit" element={<AuthenticatedFreelancerRecruitMemberSection/>} ></Route>
        <Route exact path="/freelancer-view-clients" element={<AuthenticatedFreelancerClientList/>} ></Route>
        <Route exact path="/freelancer-edit-client/:clientId" element={<AuthenticatedFreelancerEditClient/>} ></Route>
        <Route exact path="/freelancer-view-invoices/" element={<AuthenticatedFreelancerInvoiceList/>} ></Route>
        <Route exact path="/freelancer-view-invoices/edit" element={<AuthenticatedFreelancerInvoiceList/>} ></Route>
        <Route exact path="/freelancer-view-invoices/salary-spec" element={<AuthenticatedFreelancerInvoiceList/>} ></Route>
        <Route exact path="/freelancer-edit-invoice/:invoiceId" element={<AuthenticatedFreelancerEditInvoice/>} ></Route>
        <Route exact path="/client-invoices/:clientId" element={<AuthenticatedSpecificClientInvoices/>} ></Route>
        <Route exact path="/generated-invoice/:invoiceId" element={<AuthenticatedClientGeneratedInvoice/>} ></Route>
        <Route exact path="/generated-salary-invoice/:invoiceId" element={<AuthenticatedSalaryGeneratedInvoice/>} ></Route>
        <Route exact path="/change-password" element={<AuthenticatedChangePassword/>} ></Route>
        
        <Route exact path="/resetpassword/:token" element={<ResetPassword/>} ></Route>
      </Routes>
      <Footer/>
    </Router>
    </UserProvider>
  );
};
export default App;
