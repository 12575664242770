import React from 'react'
import '../../styles/FreelancerCreateClient.css';
import { countries } from 'countries-list';

function OrganizationClientForm({onChange, values, onChangeOrgMobNo}) {
    const currencyCode = ['EUR', 'SEK'];
    const allCountries = Object.values(countries);
    allCountries.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="form-div row g-3">
            <div className="">
              <label htmlFor="orgFirstName" className="label form-label">
                    Företagets namn
              </label>
              <input type="text" value={values.orgFirstName} className="form-input form-control pvt-input-field" id="orgFirstName" onChange={onChange} required/>
            </div>
          <div className="">
              <label htmlFor="orgIdNumber" className="label form-label">
              Org. Nr: / ID. Nr:
              </label>
              <input type="text" value={values.orgIdNumber} className="form-input form-control pvt-input-field" id="orgIdNumber" onChange={onChange} required/>
            </div>
            <div className="">
              <label htmlFor="orgVATNumber" className="label form-label">
              VAT-nummer (valfri)
              </label>
              <input type="text" value={values.orgVATNumber} className="form-input form-control pvt-input-field" id="orgVATNumber" onChange={onChange}/>
              <div id="vat-help" className="form-text">Om din kund befinner sig i ett land som ingår i EU, (Europeiska unionen) så ber vi dig att fylla i kundens VAT-nummer..</div>
            </div>

            {/* <div className="">
              <label htmlFor="orgLastName" className="label form-label">
              Efternamn
              </label>
              <input type="text" value="" className="form-input form-control pvt-input-field" id="orgLastName" onChange={onChange} required/>
            </div> */}
            <div className="">
              <label htmlFor="orgRecipientEmail" className=" label form-label">
              Fakturamottagare e-post (till den som har hand om betalningen av fakturan)
              </label>
              <input type='email' value={values.orgRecipientEmail} className="form-input form-control pvt-input-field" id="orgRecipientEmail" onChange={onChange} required/>
            </div>
            <div className="">
              <label htmlFor="orgReferenceName" className=" label form-label">
              Referensens namn (den person som har beställt, eller känner till beställningen av tjänsten)
              </label>
              <input type="text" value={values.orgReferenceName}  className="form-input form-control pvt-input-field pvt-input-field" id="orgReferenceName" onChange={onChange} required/>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="form-div row g-3">           
            <div className="">
              <label htmlFor="orgContactNumber" className=" label form-label">
              Kontaktens / Beställarens mobil nummer
              </label>
              <input type="text" value={values.orgContactNumber} className="form-input form-control pvt-input-field" id="orgContactNumber" onChange={onChangeOrgMobNo} required/>
            </div>
            <div className="">
            <label className="label form-label" htmlFor='orgCurrencyCode'>
                    Valutakod
              </label>
              <select
              id="orgCurrencyCode"
                className="form-select form-control wise-dropdown"
                onChange={onChange}
                value={values.orgCurrencyCode}
                required
              >
                <option defaultValue="Markera Valuta" value="">
                  Markera Valuta
                </option>
                {currencyCode.map((currCode, index) => (
                  <option key={index} value={currCode}>
                    {currCode}
                  </option>
                ))}
              </select>
            </div>
            
            <h6 className='client-address-heading mt-4'>Företags adress</h6>
            <div className="">
              <label htmlFor="orgStreet" className="label form-label">
              Gata / Box
              </label>
              <input type="text" value={values.orgStreet} className="form-input form-control pvt-input-field" id="orgStreet" onChange={onChange} required/>
            </div>
            <div className="">
              <label htmlFor="orgPostalCode" className=" label form-label">
              Landskod / Postnummer (tex. SE-441 96)
              </label>
              <input type='text' value={values.orgPostalCode} className="form-input form-control pvt-input-field" id="orgPostalCode" onChange={onChange} required/>
            </div>
            <div className="">
              <label htmlFor="orgCity" className=" label form-label">
              Stad / Plats
              </label>
              <input type="text" value={values.orgCity}  className="form-input form-control pvt-input-field pvt-input-field" id="orgCity" onChange={onChange} required/>
            </div>
            <div className=" ">
            <label htmlFor="orgCountry" className=" label form-label">
              Land
              </label>
              <select
                id="orgCountry"
                className="form-select form-control wise-dropdown"
                onChange={onChange}
                value={values.orgCountry}
                required
              >
                <option defaultValue="Markera Landet" value="">
                  Markera Landet
                </option>
                {allCountries.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrganizationClientForm;
